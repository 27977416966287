import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface MatchStatusChangeConfirmationModalProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	title?: string;
	message?: string;
}

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const MatchStatusChangeConfirmationModal: React.FC<MatchStatusChangeConfirmationModalProps> = ({
	open,
	onClose,
	onConfirm,
	title,
	message,
}) => {
	const { t } = useTranslation();

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title || t('unsavedChangesTitle')}</DialogTitle>
			<DialogContent>
				<Typography>{message || t('unsavedChangesMessage')}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					{t('no')}
				</Button>
				<Button
					onClick={onConfirm}
					color="primary"
					variant="contained"
					style={brownButtonStyle}
				>
					{t('yes')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default MatchStatusChangeConfirmationModal;
