import React, { useState } from 'react';
import { IconButton, Typography, FormControl, Divider, Button, Grid, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FilterAlt as FilterAltIcon, Close as CloseIcon } from '@mui/icons-material';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/es'; // Import the Spanish locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/en'; // Import the English locale

import { useLocationQuery, useLocationQuerySet } from 'app/shared/util/useLocationQuery';
import { FormSelectField } from 'app/shared/atoms';
import { enumtoArrayConverter } from 'app/shared/util/common';
import { TransactionStatus } from 'app/config/constants';
import { ClubsMultiSelect } from 'app/shared/molecules';
import { formatDate } from 'app/shared/util/dateHelpers';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { downloadTransactionsList } from '../transactionsApiService';
import { showToast } from 'app/shared/util/toastHelper';
import exportExcel from 'app/shared/components/exportExcel';
import PaginatedAutocomplete from 'app/shared/molecules/PaginatedAutocomplete';
import { fetchEndUsers, fetchSearchUsers } from 'app/entities/Users/usersApiService';

interface IFilterProps {
	callback?: any;
}

const ButtonWrapper = styled('div')`
	display: flex;
	justify-content: flex-end;
`;

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};
dayjs.extend(utc);

const TransactionFilterDialog: React.FC<IFilterProps> = ({ callback }) => {
	const dispatch = useDispatch<AppDispatch>();
	const searchParams = useLocationQuery();
	const setQuery = useLocationQuerySet();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation();
	const fields = ['from', 'to', 'clubs', 'filter_user', 'filter_userObj', 'status']; // Sample fields keys

	const [open, setOpen] = React.useState(false);
	const [placement] = React.useState<PopperPlacementType>();
	const [datePickerFromOpen, setDatePickerFromOpen] = useState(false);
	const [datePickerToOpen, setDatePickerToOpen] = useState(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev) => !prev);
	};

	const handleReset = (resetForm: any) => (e: any) => {
		resetForm();

		const filteredSearchParamsObject = _.omit(searchParams, fields);
		setQuery({ ...filteredSearchParamsObject, page: 1 });
		callback();
	};

	const handleApply = () => {
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	const handleDateChange = (objectKey: string, searchParams?: any) => (date: Dayjs | null) => {
		let updatedDate = date ? dayjs(date).utc().toISOString() : null;

		let param = { ...searchParams };
		if (!param?.filter_user) {
			delete param.user;
		}
		if (!param?.clubs) {
			delete param.clubs;
		}
		if (!param?.status) {
			delete param.status;
		}

		setQuery({ ...param, viewMode: 'viewAll', page: 1, [objectKey]: updatedDate });
		callback({ ...param, viewMode: 'viewAll', page: 1, [objectKey]: updatedDate });
	};

	const createTableData = (response: any, fromDate: any, toDate: any) => {
		const header2 = [
			t('date'),
			t('name'),
			t('club'),
			t('bookingDate'),
			t('bookingInitiatedBy'),
			t('resourcePaidFor'),
			t('paymentType'),
			t('amount'),
			t('status'),
			t('credits'),
			t('casa'),
		];

		const body2 = response?.map((item: any) => [
			item?.date || '',
			item?.name || '',
			item?.club || '',
			item?.startTime || '',
			item?.bookingInitiatedBy || '',
			item?.resourcePaidFor || '',
			item?.paymentType || '',
			item?.amount || '',
			item?.status || '',
			item?.credits || 0,
			item?.casaCredits || 0,
		]);

		const finalTablesBody = [header2, ...body2, []];

		const FileName = `Transactions_${moment(fromDate).format('DD-MM-YYYY')}_${moment(
			toDate
		).format('DD-MM-YYYY')}.xlsx`;

		const wb = exportExcel(`Transactions`, finalTablesBody); // Use the utility function
		// Export the workbook
		XLSX.writeFile(wb, FileName);

		showToast('Transactions report downloaded successfully', 'Success', {
			position: toast.POSITION.TOP_RIGHT,
		});
	};

	const fetchUserOptions = async (inputValue: string, page: number) => {
		try {
			const response: any = await fetchSearchUsers(inputValue, page, true);

			const normalizedData = response?.data?.map((x: any) => ({
				...x,
				id: x?.user?._id || x?._id,
			}));

			return {
				...response,
				data: normalizedData || [],
			};
		} catch (error) {
			console.error('Error fetching user options:', error);
			return {
				data: [],
			};
		}
	};

	return (
		<>
			<Box display={'flex'} alignItems={'flex-end'}>
				{!window.location?.pathname?.startsWith('/users/') && (
					<Grid container spacing={2} sx={{ marginTop: '1rem' }}>
						<Grid item>
							{/* <Typography
								style={{
									flex: 1,
									fontSize: '12px',
								}}
								className="label uppercase"
							>
								{t('startDate')}
							</Typography> */}
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale={localStorage.getItem('language') || 'en'}
							>
								<FormControl
									style={{
										marginTop: '10px',
										width: '260px',
									}}
								>
									<DatePicker
										label={t('startDate')}
										onChange={handleDateChange('from', searchParams)}
										value={
											searchParams?.from
												? dayjs.utc(searchParams?.from)
												: null
										}
										format="DD/MM/YYYY"
										timezone="UTC"
										open={datePickerFromOpen}
										onOpen={() => setDatePickerFromOpen(true)}
										onClose={() => setDatePickerFromOpen(false)}
										slotProps={{
											textField: {
												onClick: () => setDatePickerFromOpen(true),
												sx: {
													'& .MuiInputBase-root': {
													  height: '50px', 
													},
												}
											},
										}}
									/>
								</FormControl>
							</LocalizationProvider>
						</Grid>
						<Grid item>
							{/* <Typography
								style={{
									flex: 1,
									fontSize: '12px',
								}}
								className="label uppercase"
							>
								{t('endDate')}
							</Typography> */}
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale={localStorage.getItem('language') || 'en'}
							>
								<FormControl
									style={{
										marginTop: '10px',
										width: '260px',
									}}
								>
									<DatePicker
										label={t('endDate')}
										onChange={handleDateChange('to')}
										value={
											searchParams?.to ? dayjs.utc(searchParams?.to) : null
										}
										format="DD/MM/YYYY"
										timezone="UTC"
										open={datePickerToOpen}
										onOpen={() => setDatePickerToOpen(true)}
										onClose={() => setDatePickerToOpen(false)}
										slotProps={{
											textField: {
												onClick: () => setDatePickerToOpen(true),
												sx: {
													'& .MuiInputBase-root': {
													  height: '50px', 
													},
												},
											},
										}}
									/>
								</FormControl>
							</LocalizationProvider>
						</Grid>
					</Grid>
				)}

				{!window.location?.pathname?.startsWith('/users/') && (
					<Box sx={{ marginRight: '1rem' }}>
						<Button
							variant="contained"
							color="primary"
							style={brownButtonStyle}
							sx={{
								height: '48px',
							}}
							onClick={() => {
								if (searchParams?.from && searchParams?.to) {
									let values: any = searchParams;
									let params: any = {
										...values,
										fromDate: searchParams?.from
											? dayjs(searchParams?.from)
													.startOf('day')
													.format('YYYY-MM-DDTHH:mm:ss.SSS')
											: dayjs()
													.startOf('day')
													.format('YYYY-MM-DDTHH:mm:ss.SSS'),
										toDate: searchParams?.to
											? dayjs(searchParams?.to)
													.endOf('day')
													.format('YYYY-MM-DDTHH:mm:ss.SSS')
											: dayjs()
													.endOf('day')
													.format('YYYY-MM-DDTHH:mm:ss.SSS'),
									};

									if (!params?.limit) {
										delete params.limit;
									}
									if (!params?.clubs) {
										delete params.clubs;
									}
									if (!params?.status) {
										delete params.status;
									}

									dispatch(
										downloadTransactionsList(params, (data: any) => {
											createTableData(data, params?.fromDate, params?.toDate);
										})
									);
								} else {
									showToast('From & To date required', 'Error', {
										position: toast.POSITION.TOP_RIGHT,
									});
								}
							}}
						>
							{t('download')}
						</Button>
					</Box>
				)}

				<Button
					variant="outlined"
					onClick={handleClick}
					style={{
						cursor: 'pointer',
						borderRadius: '4px',
						border: '1px solid #F2F2F2',
						color: '#727272',
					}}
				>
					<FilterAltIcon style={{ marginRight: '8px', color: '#727272' }} />
					<Typography>{t('filter')}</Typography>
				</Button>
			</Box>

			<Popper
				sx={{ zIndex: 1200, minWidth: '600px', maxWidth: '600px' }}
				open={open}
				anchorEl={anchorEl}
				placement={placement}
				transition
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper style={{ padding: '30px' }}>
							<Grid container justifyContent={'space-between'}>
								<Grid>
									<Typography variant="h6" style={{ flex: 1 }}>
										{t('filterBy')}
									</Typography>
								</Grid>
								<Grid>
									<IconButton onClick={handleClose}>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>

							<Divider style={{ marginTop: '1rem' }} />

							<Formik
								initialValues={{
									...searchParams,
									clubs: searchParams?.clubs?.split(',') || [],
									status: searchParams?.status?.split(',') || [],
								}}
								onSubmit={(values, actions) => {
									let param = { ...searchParams, ...values };
									if (!values.filter_user) {
										delete param.filter_user;
									}
									if (!values.clubs) {
										delete param.clubs;
									}
									if (!values.status) {
										delete param.status;
									}

									setQuery({ ...param, viewMode: 'viewAll', page: 1 });
									callback({ ...param, viewMode: 'viewAll', page: 1 });
								}}
								enableReinitialize
							>
								{({ values, setFieldValue, resetForm }) => {
									return (
										<>
											<Form>
												<Box style={{ marginTop: '25px' }}>
													{' '}
													{!window.location?.pathname?.startsWith(
														'/users/'
													) && (
														<>
															<PaginatedAutocomplete
																id={'filter_user'}
																handleUserSelect={(
																	event: any,
																	value?: any
																) => {
																	setFieldValue(
																		'filter_user',
																		value?.id || value?._id
																	);
																	setFieldValue(
																		'filter_userObj',
																		JSON.stringify(value)
																	);
																}}
																fetchOptions={fetchUserOptions}
																selectedUser={
																	values?.filter_userObj
																		? JSON.parse(
																				values?.filter_userObj
																		  )
																		: undefined
																}
																t={t}
															/>
														</>
													)}{' '}
													<Box sx={{ marginTop: '1rem' }}>
														<ClubsMultiSelect
															label="Club"
															value={
																values?.clubs ? values?.clubs : []
															} // Pass the value from Formik's state
															onChange={(clubs: any) => {
																setFieldValue('clubs', clubs);
															}}
															multiple={true}
														/>
													</Box>
												</Box>

												<Grid
													container
													spacing={2}
													sx={{ marginTop: '25px' }}
												>
													<Grid item xs={6}>
														<FormSelectField
															label={t('transactionStatus')}
															name="status"
															options={enumtoArrayConverter(
																TransactionStatus
															)?.map((x: any) => ({
																...x,
																label: t(x?.label),
															}))}
															isMultiple={true}
															onChangeCallback={(val: any) =>
																setFieldValue('status', val)
															}
															value={values?.filter_status || []}
														/>
													</Grid>
												</Grid>

												<Grid
													container
													justifyContent={'flex-end'}
													alignItems={'center'}
													mt={'3rem'}
												>
													<Button
														onClick={handleReset(resetForm)}
														style={{
															marginRight: '1rem',
															color: '#EB5757',
															fontWeight: 600,
															textTransform: 'capitalize',
														}}
													>
														{t('resetFilters')}
													</Button>
													<ButtonWrapper>
														<Button
															variant="contained"
															color="primary"
															onClick={handleApply}
															style={brownButtonStyle}
															className="capitalize"
															type="submit"
														>
															{t('applyFilters')}
														</Button>
													</ButtonWrapper>
												</Grid>
											</Form>{' '}
										</>
									);
								}}
							</Formik>
						</Paper>
					</Fade>
				)}
			</Popper>
		</>
	);
};

export default TransactionFilterDialog;
