import * as yup from "yup";
import moment from "moment";
import { useCommonTranslations } from "./CommonTranslations";
import { VALIDATION } from "app/config/constants";

// Utility function to check for overlapping time slots
const isOverlapping = (start1: any, end1: any, start2: any, end2: any) => {
  return (
    moment(start1, "HH:mm").isBefore(moment(end2, "HH:mm")) &&
    moment(start2, "HH:mm").isBefore(moment(end1, "HH:mm"))
  );
};

// Utility function to validate the same date & court & startTime & endTime
const isSameSlot = (slot1: any, slot2: any) => {
  return (
    slot1.date === slot2.date &&
    slot1.court === slot2.court &&
    slot1.startTime === slot2.startTime &&
    slot1.endTime === slot2.endTime
  );
};

export const AddTeamValidationSchema = (existingUsers: any) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  return yup.object().shape({
    name: yup
    .string()
    .required(`${t("teamNameRequired")}`)
    .min(1, `${t("minimumOneLettersRequired")}`)
    .max(25, `${t("maximum25LettersAllowed")}`),
    teams: yup.array().of(
      yup.object().shape({
        user: yup
          .string()
          .test(
            "exist-users",
            "Selected player is already in the tournament",
            (val: string | any) => {
              if (val !== undefined && existingUsers?.includes(val)) {
                return false;
              }
              return true;
            }
          ),
        firstName: yup.string().when("user", ([user], schema: any) => {
          return !user
            ? schema
                .required(`${t("firstNameRequired")}`)
                .min(1, `${t("minimumOneLettersRequired")}`)
                .max(25, `${t("maximum25LettersAllowed")}`)
                .test("alphabets-only", t("AlphabetsOnly"), (val: any) => {
                  return (
                    val === undefined || VALIDATION.ALPHABETS_SPACE.test(val)
                  );
                })
            : schema;
        }),
        lastName: yup.string().when("user", ([user], schema: any) => {
          return !user
            ? schema
                .required(`${t("lastNameRequired")}`)
                .min(1, `${t("minimumOneLettersRequired")}`)
                .max(25, `${t("maximum25LettersAllowed")}`)
                .test("alphabets-only", t("AlphabetsOnly"), (val: any) => {
                  return (
                    val === undefined || VALIDATION.ALPHABETS_SPACE.test(val)
                  );
                })
            : schema;
        }),
        countryCode: yup.string().when("user", ([user], schema: any) => {
          return !user
            ? schema.required(`${t("countryCodeRequired")}`)
            : schema;
        }),
        phone: yup.string().when("user", ([user], schema: any) => {
          return !user
            ? schema
                .required(`${t("phoneNumberRequired")}`)
                .max(13, `${t("maximum13LettersAllowed")}`)
                .matches(/^\d+$/, t("invalidPhoneNumber"))
                .test(
                  "not-start-with-zero",
                  t("phoneShouldNotStartWithZero"),
                  (value:any) => value && !/^0/.test(value)
                )
            : schema;
        }),
        email: yup.string().when("user", ([user], schema: any) => {
          return !user
            ? schema
                .email(`${t("invalidEmail")}`)
                .required(`${t("emailRequired")}`)
            : schema;
        }),
        licenceNumber: yup
          .string()
          .required(t("newLicenseNumberRequired"))
          .matches(/^\d{7}$/, "License number must be exactly 7 digits"),
        playerLevel: yup
          .string()
          .required(`${t("playerLevelRequired")}`)
          .matches(/^\d+(\.\d{0,2})?$/, t("PlayerRange"))
          .test("range", "Player level must be between 0 and 10", (value) => {
            const numberValue = parseFloat(value);
            return numberValue >= 0 && numberValue <= 10;
          }),
      })
    ),
  });
};

export const AddTournamentValidationSchema = (step: number) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  let validation = [
    yup.object().shape({
      name: yup
        .string()
        .required(`${t("tournamentNameRequired")}`)
        .min(1, `${t("minimumOneLettersRequired")}`)
        .max(50, `${t("maximum50LettersAllowed")}`),
      gender: yup.string().required(`${t("genderRequired")}`),
      startDate: yup
        .date()
        .required(`${t("startDateRequired")}`)
        .nullable()
        .transform((originalValue, originalObject) => {
          // If the "from" field is empty, return null
          return originalObject.from ? originalValue : null;
        })
        .test("is-valid-date", t("invalidFromDate"), (value) =>
          value ? moment(value, moment.ISO_8601, true).isValid() : true
        )
        .test(
          "no-overlap",
          "Date range overlaps with existing range",
          function (value) {
            const from = value ? moment(value).format("YYYY-MM-DD") : null;
            const to = this.resolve(yup.ref("to"));

            if (!from || !to) {
              return true; // Skip validation if either is null
            }

            const isOverlap =
              Array.isArray(this.parent) &&
              this.parent?.some((existingRange) => {
                const existingFrom = moment(existingRange?.from).format(
                  "YYYY-MM-DD"
                );
                const existingTo = moment(existingRange?.to).format(
                  "YYYY-MM-DD"
                );

                return (
                  (moment(from).isSameOrAfter(existingFrom) &&
                    moment(from).isBefore(existingTo)) ||
                  (moment(to).isAfter(existingFrom) &&
                    moment(to).isSameOrBefore(existingTo)) ||
                  (moment(from).isSameOrBefore(existingFrom) &&
                    moment(to).isSameOrAfter(existingTo))
                );
              });

            return !isOverlap;
          }
        ),
      endDate: yup
        .date()
        .required(`${t("endDateRequired")}`)
        .nullable()
        .transform((originalValue, originalObject) => {
          // If the "from" field is empty, return null
          return originalObject.from ? originalValue : null;
        })
        .test("is-valid-date", t("invalidFromDate"), (value) =>
          value ? moment(value, moment.ISO_8601, true).isValid() : true
        )
        .test(
          "no-overlap",
          "Date range overlaps with existing range",
          function (value) {
            const from = value ? moment(value).format("YYYY-MM-DD") : null;
            const to = this.resolve(yup.ref("to"));

            if (!from || !to) {
              return true; // Skip validation if either is null
            }

            const isOverlap =
              Array.isArray(this.parent) &&
              this.parent?.some((existingRange) => {
                const existingFrom = moment(existingRange?.from).format(
                  "YYYY-MM-DD"
                );
                const existingTo = moment(existingRange?.to).format(
                  "YYYY-MM-DD"
                );

                return (
                  (moment(from).isSameOrAfter(existingFrom) &&
                    moment(from).isBefore(existingTo)) ||
                  (moment(to).isAfter(existingFrom) &&
                    moment(to).isSameOrBefore(existingTo)) ||
                  (moment(from).isSameOrBefore(existingFrom) &&
                    moment(to).isSameOrAfter(existingTo))
                );
              });

            return !isOverlap;
          }
        ),
      registrationDeadline: yup
        .date()
        .required(`${t("startDateRequired")}`)
        .nullable()
        .transform((originalValue, originalObject) => {
          // If the "from" field is empty, return null
          return originalObject.from ? originalValue : null;
        })
        .test("is-valid-date", t("invalidFromDate"), (value) =>
          value ? moment(value, moment.ISO_8601, true).isValid() : true
        )
        .test(
          "no-overlap",
          "Date range overlaps with existing range",
          function (value) {
            const from = value ? moment(value).format("YYYY-MM-DD") : null;
            const to = this.resolve(yup.ref("to"));

            if (!from || !to) {
              return true; // Skip validation if either is null
            }

            const isOverlap =
              Array.isArray(this.parent) &&
              this.parent?.some((existingRange) => {
                const existingFrom = moment(existingRange?.from).format(
                  "YYYY-MM-DD"
                );
                const existingTo = moment(existingRange?.to).format(
                  "YYYY-MM-DD"
                );

                return (
                  (moment(from).isSameOrAfter(existingFrom) &&
                    moment(from).isBefore(existingTo)) ||
                  (moment(to).isAfter(existingFrom) &&
                    moment(to).isSameOrBefore(existingTo)) ||
                  (moment(from).isSameOrBefore(existingFrom) &&
                    moment(to).isSameOrAfter(existingTo))
                );
              });

            return !isOverlap;
          }
        ),
      fee: yup.number().required("Participation fee is required"),
      clubs: yup.string().required("At least one club must be selected"),
      cancellationPeriodInHours: yup
        .string()
        .required("Cancellation period is required"),
      minTeams: yup
        .number()
        .required("Minimum number of teams is required")
        .test(
          "min-teams",
          "Minimum number of teams must be greater than 0",
          (value: any) => value > 0
        ),
      maxTeams: yup
        .number()
        .required("Maximum number of teams is required")
        .test(
          "max-teams",
          "Maximum number of teams must be greater than 0",
          (value: any) => value > 0
        )
        .test(
          "max-teams-greater-than-min",
          "Max Teams cannot be lesser than Min Teams",
          function (value) {
            const minTeams = this.parent.minTeams || 0; // Default to 0 if minTeams is not provided
            return value >= minTeams;
          }
        ),
    }),
    yup.object().shape({
      courts: yup
        .array()
        .of(
          yup.object().shape({
            court: yup.string().required("Court is required"),
            date: yup.string().required("Date is required"),
            startTime: yup.string().required("Start time is required"),
            endTime: yup.string().required("End time is required"),
          })
        )
        .test(
          "no-overlapping-or-same-slots",
          "Some slots are overlapping or the same.",
          function (values: any, context: any) {
            const errors: any = [];

            for (let i = 0; i < values.length; i++) {
              let error = null;
              for (let j = 0; j < values.length; j++) {
                if (i !== j) {
                  if (isSameSlot(values[i], values[j])) {
                    error = {
                      startTime: "Already selected",
                      endTime: "Already selected",
                    };
                  }

                  if (
                    values[i].court === values[j].court &&
                    values[i].date === values[j].date
                  ) {
                    if (
                      isOverlapping(
                        values[i].startTime,
                        values[i].endTime,
                        values[j].startTime,
                        values[j].endTime
                      )
                    ) {
                      error = {
                        startTime:
                          "This date and start time slot is already blocked or overlapped",
                        endTime:
                          "This date and end time slot is already blocked or overlapped",
                      };
                    }
                  }
                }
              }
              // Check if endTime is after startTime
              if (
                !moment(values[i].endTime, "HH:mm").isAfter(
                  moment(values[i].startTime, "HH:mm")
                )
              ) {
                error = {
                  startTime: "End time must be after start time",
                  endTime: "End time must be after start time",
                };
              }

              errors.push(error);
            }

            // Return false if any errors are found, otherwise true
            const isValid = errors.every((error: any) => error === null);
            if (!isValid) {
              throw new yup.ValidationError([errors], undefined, this.path);
            }
            return true;
          }
        ),
    }),
  ];

  return validation[step];
};
