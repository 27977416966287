import React, { useState } from 'react';
import {
	IconButton,
	Typography,
	FormControl,
	Divider,
	Button,
	Grid,
	Box,
	Popper,
	Paper,
	Fade,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { FilterAlt as FilterAltIcon, Close as CloseIcon } from '@mui/icons-material';
import { PopperPlacementType } from '@mui/material/Popper';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';

import 'dayjs/locale/es'; // Import the Spanish locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/en'; // Import the English locale

import { useLocationQuery, useLocationQuerySet } from 'app/shared/util/useLocationQuery';
import { FormSelectField } from 'app/shared/atoms';
import { enumtoArrayConverter } from 'app/shared/util/common';
import { CoachingSessionBookingFilterStatus } from 'app/config/constants';
import { ClubsMultiSelect } from 'app/shared/molecules';
import { fetchSearchUsers } from 'app/entities/Users/usersApiService';
import PaginatedAutocomplete from 'app/shared/molecules/PaginatedAutocomplete';

interface IFilterProps {
	callback?: any;
}

const ButtonWrapper = styled('div')`
	display: flex;
	justify-content: flex-end;
`;

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};
dayjs.extend(utc);

const CoachingSessionBookingFilterDialog: React.FC<IFilterProps> = ({ callback }) => {
	const location = useLocation();
	const searchParams = useLocationQuery();
	const setQuery = useLocationQuerySet();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation();
	const fields = ['fromDate', 'toDate', 'clubs', 'user', 'status', "filter_userObj"]; // Sample fields keys

	const [open, setOpen] = React.useState(false);
	const [placement] = React.useState<PopperPlacementType>();
	const [datePickerFromOpen, setDatePickerFromOpen] = useState(false);
	const [datePickerToOpen, setDatePickerToOpen] = useState(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev) => !prev);
		//setPlacement(newPlacement);
	};

	const handleReset = (resetForm: any) => (e: any) => {
		resetForm();

		const filteredSearchParamsObject = _.omit(searchParams, fields);
		setQuery({ ...filteredSearchParamsObject, page: 1 });
		callback();
	};

	const handleApply = () => {
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	const handleDateChange = (objectKey: string, setFieldValue: any) => (date: Dayjs | null) => {
		//setSelectedDate(date);
		setFieldValue(objectKey, date ? dayjs(date).utc().toISOString() : null);
	};

	const fetchUserOptions = async (inputValue: string, page: number) => {
		try {
			const response: any = await fetchSearchUsers(inputValue, page, true);

			const normalizedData = response?.data?.map((x: any) => ({
				...x,
				id: x?.user?._id || x?._id,
			}));

			return {
				...response,
				data: normalizedData || [],
			};
		} catch (error) {
			console.error('Error fetching user options:', error);
			return {
				data: [],
			};
		}
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleClick}
				style={{
					cursor: 'pointer',
					borderRadius: '4px',
					border: '1px solid #F2F2F2',
					color: '#727272',
				}}
			>
				<FilterAltIcon style={{ marginRight: '8px', color: '#727272' }} />
				<Typography>{t('filter')}</Typography>
			</Button>

			<Popper
				sx={{ zIndex: 1200, minWidth: '600px', maxWidth: '600px' }}
				open={open}
				anchorEl={anchorEl}
				placement={placement}
				transition
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper style={{ padding: '30px' }}>
							<Grid container justifyContent={'space-between'}>
								<Grid>
									<Typography variant="h6" style={{ flex: 1 }}>
										{t('filterBy')}
									</Typography>
								</Grid>
								<Grid>
									<IconButton onClick={handleClose}>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>

							<Divider style={{ marginTop: '1rem' }} />

							<Formik
								initialValues={{
									...searchParams,
									clubs: searchParams?.clubs?.split(',') || [],
								}}
								onSubmit={(values, actions) => {
									let param = { ...searchParams, ...values };
									if (!values.filter_user) {
										delete param.filter_user;
									}

									setQuery({ ...param, viewMode: 'viewAll', page: 1 });
									callback({ ...param, viewMode: 'viewAll', page: 1 });
								}}
								enableReinitialize
							>
								{({ values, setFieldValue, resetForm }) => {
									return (
										<>
											<Form>
												<Box style={{ marginTop: '25px' }}>
													{!location?.pathname?.startsWith('/users/') && (
														<>
															<Typography
																style={{
																	flex: 1,
																	fontSize: '12px',
																	marginBottom: '1rem'
																}}
																className="label uppercase"
															>
																{t('user')}
															</Typography>
															<PaginatedAutocomplete
																id={'filter_user'}
																handleUserSelect={(
																	event: any,
																	value?: any
																) => {
																	setFieldValue(
																		'user',
																		value?.id || value?._id
																	);
																	setFieldValue(
																		'filter_userObj',
																		JSON.stringify(value)
																	);
																}}
																fetchOptions={fetchUserOptions}
																selectedUser={
																	values?.filter_userObj
																		? JSON.parse(
																				values?.filter_userObj
																		  )
																		: undefined
																}
																t={t}
															/>
														</>
													)}
													<Box sx={{ marginTop: '1rem' }}>
														<ClubsMultiSelect
															label="Club"
															value={
																values?.clubs ? values?.clubs : []
															} // Pass the value from Formik's state
															onChange={(clubs: any) => {
																setFieldValue('clubs', clubs);
															}}
															multiple={true}
														/>
													</Box>
												</Box>

												<Grid
													container
													spacing={2}
													sx={{ marginTop: '1rem' }}
												>
													<Grid item>
														<Typography
															style={{
																flex: 1,
																fontSize: '12px',
															}}
															className="label uppercase"
														>
															{t('startDate')}
														</Typography>
														<LocalizationProvider
															dateAdapter={AdapterDayjs}
															adapterLocale={
																localStorage.getItem('language') ||
																'en'
															}
														>
															<FormControl
																//fullWidth
																style={{
																	marginTop: '10px',
																	width: '260px',
																}}
															>
																<DatePicker
																	onChange={handleDateChange(
																		'fromDate',
																		setFieldValue
																	)}
																	value={
																		values?.fromDate
																			? dayjs.utc(
																					values?.fromDate
																			  )
																			: null
																	}
																	format="DD/MM/YYYY"
																	timezone="UTC"
																	open={datePickerFromOpen}
																	onOpen={() =>
																		setDatePickerFromOpen(true)
																	}
																	onClose={() =>
																		setDatePickerFromOpen(false)
																	}
																	slotProps={{
																		textField: {
																			onClick: () =>
																				setDatePickerFromOpen(
																					true
																				),
																		},
																	}}
																/>
															</FormControl>
														</LocalizationProvider>
													</Grid>
													<Grid item>
														<Typography
															style={{ flex: 1, fontSize: '12px' }}
															className="label uppercase"
														>
															{t('endDate')}
														</Typography>
														<LocalizationProvider
															dateAdapter={AdapterDayjs}
															adapterLocale={
																localStorage.getItem('language') ||
																'en'
															}
														>
															<FormControl
																//fullWidth
																style={{
																	marginTop: '10px',
																	width: '260px',
																}}
															>
																<DatePicker
																	onChange={handleDateChange(
																		'toDate',
																		setFieldValue
																	)}
																	value={
																		values?.toDate
																			? dayjs.utc(
																					values?.toDate
																			  )
																			: null
																	}
																	format="DD/MM/YYYY"
																	timezone="UTC"
																	open={datePickerToOpen}
																	onOpen={() =>
																		setDatePickerToOpen(true)
																	}
																	onClose={() =>
																		setDatePickerToOpen(false)
																	}
																	slotProps={{
																		textField: {
																			onClick: () =>
																				setDatePickerToOpen(
																					true
																				),
																		},
																	}}
																/>
															</FormControl>
														</LocalizationProvider>
													</Grid>
												</Grid>

												<Grid
													container
													spacing={2}
													sx={{ marginTop: '25px' }}
												>
													<Grid item xs={6}>
														<FormSelectField
															label={t('status')}
															name="status"
															options={enumtoArrayConverter(
																CoachingSessionBookingFilterStatus
															)?.map((x: any) => ({
																...x,
																label: t(x?.label),
															}))}
														/>
													</Grid>
												</Grid>

												<Grid
													container
													justifyContent={'flex-end'}
													alignItems={'center'}
													mt={'3rem'}
												>
													<Button
														onClick={handleReset(resetForm)}
														style={{
															marginRight: '1rem',
															color: '#EB5757',
															fontWeight: 600,
															textTransform: 'capitalize',
														}}
													>
														{t('resetFilters')}
													</Button>
													<ButtonWrapper>
														<Button
															variant="contained"
															color="primary"
															onClick={handleApply}
															style={brownButtonStyle}
															className="capitalize"
															type="submit"
														>
															{t('applyFilters')}
														</Button>
													</ButtonWrapper>
												</Grid>
											</Form>{' '}
										</>
									);
								}}
							</Formik>
						</Paper>
					</Fade>
				)}
			</Popper>
		</>
	);
};

export default CoachingSessionBookingFilterDialog;
