import * as yup from "yup";
import { useCommonTranslations } from "./CommonTranslations";

export const AddCouponValidationSchema = (userType?: string) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  let schema = yup.object().shape({
    name: yup
      .string()
      .required(`${t("couponNameRequired")}`)
      .min(1, `${t("minimum1LettersAllowed")}`)
      .max(25, `${t("maximum25LettersAllowed")}`),
    type: yup.string().required(`${t("typeRequired")}`),
    couponUse: yup.string().required(`${t("couponUseRequired")}`),
    value: yup
      .string()
      .required("Value is required")
      .matches(/^\d*\.?\d+$/, "Value must be a valid number")
      .test("min-max", "Value must be between 1 and 100", function (value) {
        const { type } = this.parent; // Access 'type' field from the same object
        const floatValue = parseFloat(value);
        if (type === "percentage") {
          // Validate range only for 'percentage'
          return floatValue >= 1 && floatValue <= 100;
        }
        // No additional validation for 'flat-off'
        return true;
      }),
    usageCount: yup
      .string()
      .required(t("usageCountRequired"))
      .matches(/\d+/, "Count must be a valid number")
      .test("min", "Count must be greater than 1", (value) => {
        const floatValue = parseFloat(value);
        return floatValue >= 1;
      }),
    fromDate: yup.string().required(`${t("fromDateRequired")}`),
    toDate: yup
      .string()
      .required(`${t("toDateRequired")}`)
      .test(
        "is-valid-range",
        "To date must be after from date",
        function (value) {
          const { fromDate } = this.parent;
          if (!fromDate || !value) return true; // If either fromDate or toDate is not provided, don't perform validation
          return new Date(fromDate) <= new Date(value); // Check if toDate is after fromDate
        }
      ),
  });

  if (userType === "club_admin") {
    schema = schema.shape({
      clubs: yup
        .array()
        .required(t("clubsRequired"))
        .min(1, t("clubsRequired")),
    });
  }

  return schema;
};
