import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import renderPageForm from "./PageFormRenderer";
import MUIDataGrid from "./MUIDataGrid";
import Filter from "./PageFilterRenderer";
import { useLocationQuery } from "app/shared/util/useLocationQuery";

import { AUTHORITIES, USER_ROLE, club, feeds } from "../../config/constants";
import PaymentUpdateModalForm from "app/entities/Bookings/modals/PaymentUpdateModalForm";
import CancelMultipleBookingsModalForm from "app/entities/Bookings/modals/CancelMultipleBookingsModalForm";
import { showToast } from 'app/shared/util/toastHelper';

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

const ContentContainer = styled("div")`
  flex: 1;
  padding: ${(props) => props.theme.spacing(2)};
  backgroudcolor: "#FFF";
`;

// Interface to have the props values
interface IMainContainer {
  columns?: any;
  rows?: any;
  btnText?: any;
  pageName?: any;
  gridData?: any;
  menuName?: any;
  features?: any;
  type?: any;
  currentPage?: any;
  onCurrentPageChange?: any;
  pageSize?: any;
  updateGridData?: any;
  setUpdateGridData?: any;
  loading?: any;
  refreshGrid?: any;
  feedsData?: any;
  selectedClub?: string;
  selectedDate?: Date | null;
  onSelectedClubChange?: (club: string) => void;
  onSelectedDateChange?: (date: Date | null) => void;
  updateFeedsData?: any;
  onFeedChange?: any;
  totalRecords?: number;
  meta?: any;
  handleSortModelChange?: any;
  callback?: any;
  handleCellClick?: any;
  checkboxSelection?: boolean;
  exportTableData?: any;
  disableButton?: boolean; 
  clubsList?: any;
  categories?: any;
  memberships?: any;
  sessionPackages?: any;
  rowHeight?: number;
  isTabsSpaceRequired?: boolean;
  onRowSelection?: any;
  changeBookingViewMode?: any;
  bookingViewMode?: any;
  showAddButtons?:any;
  showSearchFilter?:any;
}

const MainContainer: React.FC<IMainContainer> = ({
  columns,
  rows,
  btnText,
  pageName,
  menuName,
  type,
  currentPage,
  onCurrentPageChange,
  loading,
  feedsData,
  updateFeedsData,
  meta,
  callback,
  handleCellClick,
  checkboxSelection,
  exportTableData,
  disableButton,
  clubsList,
  categories,
  memberships,
  sessionPackages,
  rowHeight,
  isTabsSpaceRequired = false,
  onRowSelection,
  refreshGrid,
  changeBookingViewMode,
  bookingViewMode,
  showAddButtons=true,
  showSearchFilter=true,
  ...containerProps
}) => {
  const searchParams = useLocationQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const loggedInUserType = localStorage.getItem("userType");
  const [isPaymentUpdateModalOpen, setPaymentUpdateModalOpen] = useState(false);
  const [totalPaidAmount, setTotalPaidAmount] = useState<number>(0);
  const [selectedBookingIds, setSelectedBookingIds] = useState<any[]>([]);
  const [
    isMultipleBookingsCancelModalOpen,
    setMultipleBookingsCancelModalOpen,
  ] = useState(false);

  const brownButtonStyle = {
    backgroundColor: "#333333",
    color: "white", // Text color
  };

  /**
   * Handle opening the modal window
   * @function
   * @returns updatedWorkingHours array with the updated values
   */
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Handle closing the modal window
   * @function
   * @returns updatedWorkingHours array with the updated values
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (reason !== "backdropClick") {
      setIsModalOpen(false);
      if (refreshGrid) refreshGrid();
    }
  };

  // Function to handle changes in selected booking IDs
  const handleSelectedBookingIdsChange = (newSelectedBookingIds: any[]) => {
    setSelectedBookingIds(newSelectedBookingIds);
  };

  // Function to open the Payment Update modal
  const handleRowSelection = () => {
    // Calculate total paid amount
    const totalAmount = rows
      ?.filter((row: any) => selectedBookingIds?.includes(row?._id))
      ?.reduce((acc: any, row: any) => acc + parseFloat(row?.balanceAmount), 0);

    setTotalPaidAmount(totalAmount);
    setPaymentUpdateModalOpen(true);
  };

  const handleClosePaymentUpdateModal = () => {
    setPaymentUpdateModalOpen(false);
    refreshGrid();
  };

  // Function to open the Payment Update modal
  const handleOpenMultipleCancelBookingModal = () => {
    let isCompletedBookings = rows?.filter((row: any) => selectedBookingIds?.includes(row?._id) && (row?.status === 'completed' || row?.status === 'confirmed' || row?.status === 'cancelled'))?.length > 0;
    if(isCompletedBookings) {
      showToast(t('pleaseDeselectCompletedCancelledBookings'), 'Error', {
				position: toast.POSITION.TOP_RIGHT,
			});
    } else {
      setMultipleBookingsCancelModalOpen(true);
    }
  };

  const handleCloseMultipleCancelBookingModal = () => {
    setMultipleBookingsCancelModalOpen(false);
    refreshGrid();
  };

  return (
    <ContentContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {(pageName && menuName !== feeds) &&
            <Grid container spacing={2} alignItems="center" mt="0">
              {pageName !== "" && menuName !== feeds ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        color: "#45464E",
                        fontSize: "26px",
                        marginBottom: isTabsSpaceRequired ? "60px" : "unset",
                      }}
                    >
                      {pageName}
                    </Typography>
                  </Grid>
                  {(loggedInUserType !== AUTHORITIES?.club_admin ||
                    (loggedInUserType === AUTHORITIES?.club_admin &&
                      menuName !== club))  && showAddButtons && (
                    <Grid item xs={12} sm={6} justifyContent={"flex-end"}>
                      <ButtonWrapper>
                        {exportTableData && (
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={disableButton}
                            onClick={exportTableData}
                            style={{
                              backgroundColor: disableButton ? "#f0f0f0" : "#FFF", // Light gray for disabled
                              borderColor: disableButton ? "#a0a0a0" : "#000000", // Gray text for disabled
                              color: disableButton ? "#a0a0a0" : "#000", // Gray text for disabled
                            }}
                          >
                            <FileDownloadIcon />
                            {t("exportTable")}
                          </Button>
                        )}
                        {menuName === "Booking" ? (
                          <>
                            {btnText && (
                              <>
                                {" "}
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    changeBookingViewMode();
                                  }}
                                  style={{
                                    marginLeft: "1rem",
                                  }}
                                >
                                  {bookingViewMode === "today"
                                    ? t("viewAll")
                                    : t("Today")}
                                </Button>
                                {selectedBookingIds?.length > 0 && (
                                  <>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={handleRowSelection}
                                      style={{
                                        marginLeft: "1rem",
                                        border: "1px solid #333333",
                                        color: "#333333",
                                      }}
                                    >
                                      {t("markAsPaid")}
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="warning"
                                      onClick={
                                        handleOpenMultipleCancelBookingModal
                                      }
                                      style={{
                                        marginLeft: "1rem",
                                      }}
                                    >
                                      {t("cancel")}
                                    </Button>
                                  </>
                                )}
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleOpenModal}
                                  style={{
                                    ...brownButtonStyle,
                                    marginLeft: "1rem",
                                    fontWeight: 600,
                                  }}
                                >
                                  <AddIcon />
                                  {btnText}
                                </Button>
                              </>
                            )}
                            {isPaymentUpdateModalOpen && (
                              <>
                                <PaymentUpdateModalForm
                                  open={isPaymentUpdateModalOpen}
                                  onClose={handleClosePaymentUpdateModal}
                                  selectedBookingIds={selectedBookingIds}
                                  totalPaidAmount={totalPaidAmount}
                                />
                              </>
                            )}
                            {isMultipleBookingsCancelModalOpen && (
                              <>
                                <CancelMultipleBookingsModalForm
                                  open={isMultipleBookingsCancelModalOpen}
                                  onClose={handleCloseMultipleCancelBookingModal}
                                  selectedBookingIds={selectedBookingIds}
                                />
                              </>
                            )}
                          </>
                        ) : (menuName === "Admin Users" || menuName === "End Users") &&
                          loggedInUserType === AUTHORITIES?.super_admin ? (
                          <>
                            {btnText && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenModal}
                                style={{
                                  ...brownButtonStyle,
                                  marginLeft: "1rem",
                                  fontWeight: 600,
                                }}
                              >
                                <AddIcon />
                                {btnText}
                              </Button>
                            )}
                          </>
                        ) : menuName === "Coaching Management" ? (
                          <>
                            {loggedInUserType !== USER_ROLE.COACH && btnText && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenModal}
                                style={{
                                  ...brownButtonStyle,
                                  marginLeft: "1rem",
                                  fontWeight: 600,
                                }}
                              >
                                <AddIcon />
                                {btnText}
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {btnText && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenModal}
                                style={{
                                  ...brownButtonStyle,
                                  marginLeft: "1rem",
                                  fontWeight: 600,
                                }}
                              >
                                <AddIcon />
                                {btnText}
                              </Button>
                            )}
                          </>
                        )}
                      </ButtonWrapper>
                    </Grid>
                  )}
                  {isModalOpen && (
                    <>
                      {renderPageForm({
                        menuName,
                        isModalOpen,
                        handleCloseModal,
                        searchParams,
                        ...containerProps,
                      })}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </Grid>
          }

          {/* Common all page filters*/}
          {showSearchFilter && menuName !== feeds && loggedInUserType !== USER_ROLE.COACH && (
            <Filter
              callback={callback}
              menuName={menuName}
              categories={categories}
              memberships={memberships}
              clubsList={clubsList}
              sessionPackages={sessionPackages}
              t={t}
            />
          )}

          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            {/* Main content grid items */}
            {columns?.length !== 0 && menuName !== feeds ? (
              <MUIDataGrid
                columns={columns}
                rows={rows}
                onCurrentPageChange={onCurrentPageChange}
                menuName={menuName}
                meta={meta}
                callback={callback}
                handleCellClick={handleCellClick}
                checkboxSelection={checkboxSelection}
                rowHeight={rowHeight}
                loading={loading}
                selectedBookingIds={selectedBookingIds} // Pass selected booking IDs
                onRowSelection={onRowSelection} // Pass row selection handler
                handleSelectedBookingIdsChange={handleSelectedBookingIdsChange}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default MainContainer;
