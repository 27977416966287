import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
	Box,
	Toolbar,
	IconButton,
	Grid,
	Popover,
	MenuList,
	MenuItem,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Menu as MenuIcon } from '@mui/icons-material';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo_Casa_Padel from '../../../content/assets/logo-casa-padel.png';
import LanguageSwitcher from '../../shared/components/LanguageSwitcher';
import {
	BroadcastMessageIcon,
	USER_ROLE,
	LogOutMenuImage,
	UsersMenuImage,
} from '../../config/constants';
import SvgImage from 'app/shared/components/SvgImage';
import { BroadcastMessageModal } from 'app/shared/molecules/BroadcastMessage';
import { logout } from 'app/shared/components/authentication';

// Define the component-specific styles using the `styled` function
const StyledAppBar = styled('div')(({ theme }) => ({
	backgroundColor: '#FFFFFF',
	borderBottom: '1px solid #d3d3d3',
	position: 'sticky',
	top: 0,
	zIndex: 2,
}));

interface HeaderProps {
	userType?: any;
	profileData?: any;
	toggleSidebar?: any;
}

const Header: React.FC<HeaderProps> = ({ userType, profileData, toggleSidebar }: any) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [showBroadcastMessage, setshowBroadcastMessage] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<StyledAppBar>
			<Toolbar>
				<Grid container alignItems="center">
					{/* Logo on the left */}
					<Grid item xs={2} sm={6}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{profileData && (
								<IconButton onClick={toggleSidebar} sx={{ marginRight: '5px' }}>
									<MenuIcon />
								</IconButton>
							)}

							{location.pathname === '/court-bookings' ? (
								<img
									src={Logo_Casa_Padel}
									alt="Resa Padel Logo"
									style={{
										top: '11.38px',
										left: '42px',
									}}
									width={'37px'}
								/>
							) : (
								<Link to={`/court-bookings`}>
									<img
										src={Logo_Casa_Padel}
										alt="Resa Padel Logo"
										style={{
											top: '11.38px',
											left: '42px',
										}}
										width={'37px'}
									/>
								</Link>
							)}
						</Box>
					</Grid>
					{/* Notifications and Avatar on the right */}
					<Grid item xs={10} sm={6} container justifyContent="flex-end">
						{profileData && userType !== USER_ROLE.COACH && (
							<IconButton
								color="inherit"
								onClick={() => setshowBroadcastMessage(true)}
							>
								<img src={BroadcastMessageIcon} alt="broadcast-messageIcon" />
							</IconButton>
						)}
						<LanguageSwitcher />

						{profileData && (
							<IconButton aria-describedby={id} onClick={handleClick} color="inherit">
								<Avatar
									src={profileData?.profilePic ? profileData?.profilePic : ''}
									alt={`${profileData?.firstName} ${profileData?.lastName}`}
								/>
							</IconButton>
						)}

						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<MenuList>
								{profileData?.firstName && (
									<MenuItem
										onClick={() => {
											navigate(`/profile`);
											handleClose();
										}}
									>
										<ListItemIcon>
											<SvgImage
												src={UsersMenuImage}
												width="24px"
												height="24px"
											/>
										</ListItemIcon>
										<ListItemText>
											{' '}
											{profileData?.firstName} {profileData?.lastName}
										</ListItemText>
									</MenuItem>
								)}
								<MenuItem
									onClick={() => {
										logout();
										navigate(
											userType === USER_ROLE.COACH
												? '/coach/signIn'
												: `/signIn`
										);
									}}
								>
									<ListItemIcon>
										<SvgImage
											src={LogOutMenuImage}
											width="24px"
											height="24px"
										/>
									</ListItemIcon>
									<ListItemText>{t('logOut')}</ListItemText>
								</MenuItem>
							</MenuList>
						</Popover>
					</Grid>
				</Grid>
			</Toolbar>
			{showBroadcastMessage && (
				<BroadcastMessageModal
					open={showBroadcastMessage}
					onClose={() => setshowBroadcastMessage(false)}
				/>
			)}
		</StyledAppBar>
	);
};

export default Header;
