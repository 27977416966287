import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Box,
  Divider,
  Paper,
  Slide,
  Typography,
  IconButton,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { useFormik, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import COUNTRY_CODES from "../../../shared/data/CountryCodes.json";
import { extractDialCode } from "app/shared/components/CountryCodeExtractor";
import getCountryNameByDialCode from "app/shared/components/CountryNameExtractor";
import {
  POST,
  PUT,
  USER_MANAGEMENT,
  CLUBS,
} from "../../../config/API.constants";
import {
  CUSTOM_ERROR_MSG,
  HANDLE_ERROR,
  HTTP_CALL,
} from "../../../config/http";
import { toast } from "react-toastify";
import { showToast } from "../../../shared/util/toastHelper";
import { SelectChangeEvent } from "@mui/material/Select";
import { fetchDataFromApi } from "../../../entities/Clubs/clubsApiService";
import { fetchRoleDataFromApi } from "../../../entities/Users/usersApiService";
import { useTranslation } from "react-i18next";
import { ActionTypes, adminUsers, AUTHORITIES, endUsers } from "../../../config/constants";
import StyledCustomTextField from "app/shared/components/StyledTextField";
import ConfirmDialog from "app/shared/components/ConfirmDialog"; 
import { FormTextField } from "app/shared/atoms";
interface ModalFormProps {
  userType: string;
  open: boolean;
  onClose: () => void;
  action?: any;
  formData?: any;
  updateGridData?: any;
  setUpdateGridData?: any;
  refreshGrid?: any;
  page?: any;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const listClubsAPI = CLUBS.LIST_CLUBS;
const listRolesAPI = USER_MANAGEMENT.API_END_POINTS.ROLE_LIST;

const AddNewUserModalForm: React.FC<ModalFormProps> = ({
  userType,
  open,
  onClose,
  action,
  formData,
  updateGridData,
  setUpdateGridData,
  refreshGrid,
  page,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [clubName, setClubName] = useState([]);
  const [clubId] = useState<string[]>([]);
  const [clubNames, setClubNames] = useState<string[]>( // Make sure to use string[]
    action === ActionTypes?.EDIT && !isEmpty(formData?.clubs)
      ? formData?.clubs
      : []
  );
  const [value, setValue] = React.useState("0");
  const [roleList, setRoleList] = useState([]);
  const [currentPage] = React.useState(0);
  const [pageSize] = React.useState(10000);
  const { t } = useTranslation();
  const [clubNamesError, setClubNamesError] = React.useState("");
  const loggedInUserType = localStorage.getItem("userType");

  useEffect(() => {
    const fetchClubData = async () => {
      try {
        const clubListAPIData: any = await fetchDataFromApi(
          listClubsAPI,
          currentPage,
          pageSize
        ); // Call the data fetching function
        const clubRowArr: any = [];
        clubListAPIData?.forEach((club: any) => {
          const reqObj = {
            id: club?._id,
            ...club,
          };
          clubRowArr.push(reqObj);
        });
        // Update the state with the fetched clubRowArr data
        const activeClubs = clubRowArr?.filter(
          (club: any) => club.status === "active"
        );
        setClubName(activeClubs);
      } catch (error) {
        // HANDLE_ERROR(error);
      }
    };
    if (open) {
      fetchClubData();
    }
  }, [open]);

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const roleListAPIData = await fetchRoleDataFromApi(listRolesAPI); // Call the data fetching function
        const roleRowArr: any = [];
        roleListAPIData?.forEach((role: any) => {
          const reqObj = {
            id: role?._id,
            ...role,
          };
          roleRowArr.push(reqObj);
        });
        // Update the state with the fetched clubRowArr data
        setRoleList(roleRowArr);
      } catch (error) {
        // HANDLE_ERROR(error);
      }
    };
    if (open) {
      fetchRoleData();
    }
  }, [open]);

  // Define Yup validation schema
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z\s'-]+$/, t("invalidFirstName"))
      .min(1, "Minimum 1 letters required")
      .max(25, "Maximum 25 letters allowed"),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(/^[a-zA-Z\s'-]+$/, t("invalidLastName"))
      .min(1, "Minimum 1 letters required")
      .max(25, "Maximum 25 letters allowed"),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.*[A-Z]$/i, t("invalidEmail"))
      .required("Email is required"),
    countryCode: Yup.string().required(`${t("countryCodeRequired")}`),
    phone: Yup
      .string()
      .required(t("phoneNumberRequired"))
      .max(13, `${t("maximum13LettersAllowed")}`)
      .matches(/^\d+$/, t("invalidPhoneNumber"))
      .test(
        "not-start-with-zero",
        t("phoneShouldNotStartWithZero"),
        (value:any) => value && !/^0/.test(value)
      ),
    userType: Yup.string().required("User Type is required"),
    playerLevel: Yup.string()
    .required("Player Range is required")
    .matches(/^\d+(\.\d{0,2})?$/, "Player Range allows maximum of 2 decimal points")
    .test("range", "Player level must be between 0 and 10", (value) => {
      const numberValue = parseFloat(value);
      return numberValue >= 0 && numberValue <= 10;
    })
  });

  // Use Formik hook
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phone: 0,
      playerLevel: 0,
      userType: "super_admin",
      clubNames: [],
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values, action);
    },
  });

  useEffect(() => {
    let pp:any = formData?.playerLevel ? parseFloat(formData?.playerLevel).toFixed(2) : "0";
    formik.setValues({
      ...formik.values,
      firstName: formData?.firstName || "",
      lastName: formData?.lastName || "",
      email: formData?.email || "",
      userType: formData?.userType || "super_admin",
      clubNames: formData?.clubs || [],
      phone: formData?.phone || 0,
      countryCode: getCountryNameByDialCode(formData?.countryCode) || "",
      ...(userType === endUsers && {
        playerLevel: pp
      }),
    });
  }, [formData]);

  /**
   * Handle form data with changes.
   *
   * @function
   * @returns void
   */

  const handleChange = (objectKey: string) => (e: any) => {
    let value = e.target.value;
     if(objectKey === "playerLevel") {
      const trimmedValue = value.toString().trim();
      const isValidNumber = /^\d+(\.\d{0,2})?$/.test(trimmedValue); // Allowing empty string as valid
      if (isValidNumber) {
          value = value;
      }
    }
    // Capitalize the first letter of each word
    if (objectKey === "firstName" || objectKey === "lastName") {
      value = value.replace(/\b\w/g, (char: any) => char.toUpperCase());
    }
    formik.handleChange({
      target: {
        name: objectKey,
        value: value,
      },
    });
  };

  const handlePhoneClick = () => {
    // Remove leading zero when the phone field is clicked
    if (formik.values.phone === 0) {
      formik.setFieldValue("phone", "");
    }
  };

  const handlePlayerLevelClick = () => {
    // Remove leading zero when the phone field is clicked
    if (formik.values.playerLevel === 0) {
      formik.setFieldValue("playerLevel", "");
    }
  };

  /**
   * Checks form data for the valid data.
   *
   * @function
   * @returns {Boolean} Boolean value of hasValues whether the validity is succcess or not
   */

  const checkRequiredData = () => {
    return (
      (formik.touched.firstName && formik.errors.firstName) ||
      (formik.touched.lastName && formik.errors.lastName) ||
      (formik.touched.email && formik.errors.email) ||
      (formik.touched.userType && formik.errors.userType)
    );
  };

  useEffect(() => {
    checkRequiredData();
  }, [formik.values]);

  /**
   * Handle submit with all the form data collected in each step
   *
   * @function
   * @returns void
   */

  const handleSubmit = async (values: any, action: any) => {
    if (values && userType === endUsers) {
      const createANewClubAPI =
        formData?._id ? `${USER_MANAGEMENT.API_END_POINTS.GET_ALL_END_USERS}/${formData?._id}`:`${USER_MANAGEMENT.API_END_POINTS.GET_ALL_END_USERS}`;
        
      const method =
      formData?._id ? PUT:POST;

      let response: any = {};
      
      try {
        let params =  {
            firstName: values?.firstName,
            lastName: values?.lastName,
            status: "active",
            email: values?.email,
            countryCode: values?.countryCode
              ? parseInt(extractDialCode(values?.countryCode) || "0", 10)
              : 0,
            phone: values?.phone ? parseInt(values?.phone, 10) : 0,
            playerLevel: values?.playerLevel ? parseFloat(values?.playerLevel) : 0
          };

        response = await HTTP_CALL(createANewClubAPI, method, "", params);

        if (response.data.statusCode === 200) {
          if (response.data.data !== null) {
            showToast(t(formData?._id? "successCustomerUpdate":"successCustomerAdd"), "Success", {
              position: toast.POSITION.TOP_RIGHT,
            });
            if (typeof refreshGrid === "function") {
              refreshGrid(0, 1000);
              onClose();
            } else
              onClose();
          }
          formik.setSubmitting(false);
        } else {
          CUSTOM_ERROR_MSG(
            response.data.message ? response.data.message : response.statusText
          );
          formik.setSubmitting(false);
        }
      } catch (error: any) {
        HANDLE_ERROR(error);
        formik.setSubmitting(false);
      }
      return;
    }

    if (
      formik.values.userType === "club_admin" &&
      formik.values.clubNames.length === 0
    ) {
      // Show validation error message for clubNames field
      setClubNamesError("Please select club");
      formik.setSubmitting(false);
      return; // Stop form submission if clubNames field is empty
    }
    // Clear any previous validation error message for clubNames field
    setClubNamesError("");
    if (action === ActionTypes?.CREATE) {
      const createANewClubAPI =
        USER_MANAGEMENT.API_END_POINTS.ADD_ADMIN_USER_DETAILS;
      let response: any = {};
      try {
        let params = {};
        if (values.userType === "super_admin") {
          params = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            countryCode: values?.countryCode
              ? parseInt(extractDialCode(values?.countryCode) || "0", 10)
              : 0,
            phone: values?.phone ? parseInt(values?.phone, 10) : 0,
            role:
              values.userType === "super_admin"
                ? fetchRoleID("super_admin")
                : values.role,
            userType: values?.userType,
          };
        } else {
          params = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            countryCode: values?.countryCode
              ? parseInt(extractDialCode(values.countryCode) || "0", 10)
              : 0,
            phone: values?.phone ? parseInt(values?.phone, 10) : 0,
            role: values?.role,
            clubs: values?.clubNames?.filter((clubId: any) => clubId !== null),
            userType: values?.userType,
          };
        }
        response = await HTTP_CALL(createANewClubAPI, POST, "", params);
        if (response.data.statusCode === 200) {
          if (response.data.data !== null) {
            showToast(t("successAdminAdd"), "Success", {
              position: toast.POSITION.TOP_RIGHT,
            });
            if (typeof refreshGrid === "function") {
              refreshGrid(0, 1000);
            }
            onClose();
          }
        } else {
          CUSTOM_ERROR_MSG(
            response.data.message ? response.data.message : response.statusText
          );
          formik.setSubmitting(false);
        }
      } catch (error: any) {
        HANDLE_ERROR(error);
        formik.setSubmitting(false);
      }
    } else {
      const updateAdminUserAPI =
        USER_MANAGEMENT.API_END_POINTS.ADD_ADMIN_USER_DETAILS;
      let response: any = {};
      let params: any = {};
      params = {
        ...values,
        countryCode: values?.countryCode
          ? parseInt(extractDialCode(values.countryCode) || "0", 10)
          : 0,
      };

      try {
        response = await HTTP_CALL(
          updateAdminUserAPI + "/" + (formData?._id || formData?.id),
          PUT,
          "",
          params
        );
        if (response.data.statusCode === 200) {
          if (response.data.data !== null) {
            showToast(t("successAdminEdit"), "Success", {
              position: toast.POSITION.TOP_RIGHT,
            });
            if (typeof refreshGrid === "function") {
              refreshGrid(0, 1000);
            }
            onClose();
          }
        } else {
          CUSTOM_ERROR_MSG(
            response.data.message ? response.data.message : response.statusText
          );
          formik.setSubmitting(false);
        }
      } catch (error: any) {
        HANDLE_ERROR(error);
        formik.setSubmitting(false);
      }
    }
  };

  // Separate handlers for Select components
  const handleCountryCodeChange = (event: SelectChangeEvent) => {
    handleChange("countryCode")(event);
  };

  const fetchRoleID = (roleName: string) => {
    const roleDataFetched = roleList?.filter(
      (role: any) => role.key === roleName
    );
    const roleIdFetched = roleDataFetched[0]["id"] || "";
    return roleIdFetched;
  };

  // Separate handlers for Select components
  const handleUserTypeChange = (event: SelectChangeEvent) => {
    formik.handleChange(event); // Use formik.handleChange to update the form value
    const roleId = fetchRoleID(event.target.value);
    formik.setFieldValue("role", roleId); // Set the role value based on the selected userType
  };

  const handleClubsDropdownChange = (
    event: SelectChangeEvent<typeof clubId>
  ) => {
    const selectedClubIds = event?.target?.value as string[];
    // Ensure clubNames is always an array
    const clubNamesArray = Array.isArray(selectedClubIds)
      ? selectedClubIds
      : selectedClubIds
      ? [selectedClubIds]
      : [];
    // Find the selected clubs in the clubNameList
    const selectedClubs = clubName?.filter((club: any) =>
      selectedClubIds?.includes(club?.id)
    );
    // Extract the IDs of selected clubs
    const clubIdsFetched: string[] =
      selectedClubs?.map((selectedClub: any) => selectedClub.id) || [];

    formik.setFieldValue("clubNames", clubIdsFetched); // Set the clubs field in formik values
    setClubNames(clubNamesArray);
    setClubNamesError("");
  };

  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (formik?.dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      if (typeof refreshGrid === "function") {
        refreshGrid(0, 1000);
      }
      if (action === ActionTypes.CREATE) {
        formik.resetForm();
      }

      if (setUpdateGridData) {
        setUpdateGridData(false);
      }
      setClubName([]);
      setClubNames([]);
      onClose();
    }
  };

    // Handle close confirmation dialog
    const handleCancelClose = () => {
      setConfirmOpen(false);
    };
  
    // Handle confirm close action
    const handleConfirmClose = () => {
      setConfirmOpen(false);
      onClose();
    };


  return (
    <>
    <Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor:
              action === ActionTypes?.CREATE
                ? "none"
                : page !== "UserDetail"
                ? "rgba(0,0,0,0.1)"
                : "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "20px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box>
            {/* Content of the sliding modal */}
            <BoldTypography variant="h6">
              {" "}
              {userType === adminUsers ? action === ActionTypes?.CREATE
                ? t("addNewAdmin")
                : t("editAdmin") : action === ActionTypes?.CREATE
                ? t("AddNewuser")
                : t("editUser")}
            </BoldTypography>
            <Divider />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: "15px",
                right: "24px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} style={{ marginTop: "10px" }}>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="uppercase label"
                  >
                    {t("firstName")}*
                  </Typography>
                  <StyledCustomTextField
                    fullWidth
                    name="firstName"
                    value={formik?.values?.firstName}
                    onChange={handleChange("firstName")}
                    margin="normal"
                    error={
                      !!(formik.touched.firstName && formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    inputProps={{ minLength: 1, maxLength: 25 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {" "}
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="uppercase label"
                  >
                    {t("lastName")}*
                  </Typography>
                  <StyledCustomTextField
                    fullWidth
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={handleChange("lastName")}
                    margin="normal"
                    error={
                      !!(formik.touched.lastName && formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    inputProps={{ minLength: 1, maxLength: 25 }}
                  />
                </Grid>
              </Grid>
              <Typography variant="body2" className="uppercase label">
                {t("email")}*
              </Typography>
              <StyledCustomTextField
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={handleChange("email")}
                margin="normal"
                disabled={userType !== endUsers && action === ActionTypes?.EDIT}
                error={!!(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="uppercase label"
                  >
                    {t("phoneNumber")}
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      name="countryCode"
                      value={formik?.values?.countryCode}
                      onChange={handleCountryCodeChange}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            width: "400px", // Adjust the width as needed
                            marginLeft: "80px",
                          },
                        },
                      }}
                    >
                      {Array.isArray(COUNTRY_CODES) &&
                      COUNTRY_CODES?.length !== 0 ? (
                        COUNTRY_CODES?.map(
                          (countryCodeElement: any, index: any) => (
                            <MenuItem
                              key={countryCodeElement?.name}
                              value={countryCodeElement?.name}
                            >
                              {countryCodeElement?.name}
                            </MenuItem>
                          )
                        )
                      ) : (
                        <>
                          <MenuItem value="No Data">{t("noData")}</MenuItem>
                        </>
                      )}
                    </Select>
                    {formik.touched.countryCode && formik.errors.countryCode && (
                    <Typography variant="body2" color="error" sx={{ fontSize: '0.75rem'}}>
                      {formik.errors.countryCode}
                    </Typography>
                  )}
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" gutterBottom></Typography>
                  <StyledCustomTextField
                    fullWidth
                    name="phone"
                    value={formik?.values?.phone || value}
                    onChange={handleChange("phone")}
                    onClick={handlePhoneClick}
                    margin="normal"
                    placeholder={value === "0" ? "" : "0"}
                    InputProps={{
                      onFocus: () => setValue(""),
                    }}
                    error={
                      !!(formik.touched.phone && formik.errors.phone)
                    }
                    helperText={
                      formik.touched.phone && formik.errors.phone
                    }
                  />
                </Grid>
              </Grid>
                { userType === adminUsers ?
                  (<FormControl fullWidth>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="uppercase label"
                    style={{ marginTop: "15px" }}
                  >
                    {t("role")}
                  </Typography>

                  {loggedInUserType !== AUTHORITIES?.club_admin ? (
                    <Select
                      name="userType"
                      value={formik.values.userType}
                      onChange={handleUserTypeChange}
                      style={{ marginTop: "15px" }}
                      defaultValue="super_admin"
                    >
                      <MenuItem value="super_admin" selected>
                        {t("superAdmin")}
                      </MenuItem>
                      <MenuItem value="club_admin">{t("clubAdmin")}</MenuItem>
                    </Select>
                  ) : (
                    <Select
                      name="userType"
                      value={formik.values.userType}
                      onChange={handleUserTypeChange}
                      style={{ marginTop: "15px" }}
                      defaultValue="super_admin"
                    >
                      <MenuItem value="club_admin">{t("clubAdmin")}</MenuItem>
                    </Select>
                  )}
                </FormControl>) :
                (<Grid item xs={24}>
                    <Typography variant="body2" className="uppercase label">
                      {t('PlayerRange')}
                    </Typography>
                    <StyledCustomTextField
                      fullWidth
                      name="playerLevel"
                      value={formik?.values?.playerLevel || value}
                      onChange={handleChange("playerLevel")}
                      onClick={handlePlayerLevelClick}
                      margin="normal"
                      placeholder={value === "0" ? "" : "0"}
                      InputProps={{
                        onFocus: () => setValue(""),
                      }}
                    />
                    {formik?.touched?.playerLevel && formik?.errors?.playerLevel &&
                    	<Box
                        className="error-message"
                      >
                        <span
                            className="MuiFormHelperText-root Mui-error"
                            style={{
                              color: '#d32f2f',
                              marginRight:
                                '10px',
                              fontSize:
                                '12px',
                            }}
                          >
                            {formik?.errors?.playerLevel}
                          </span>
                        </Box>}
                  </Grid>)
              }
              
              {formik.values.userType === "club_admin" && (
                <FormControl fullWidth>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className="uppercase label"
                    style={{ marginTop: "15px" }}
                  >
                    {t("club")}
                  </Typography>
                  <Select
                    name="clubs"
                    value={clubNames}
                    onChange={handleClubsDropdownChange}
                    style={{ marginTop: "15px" }}
                    multiple
                  >
                    {action === ActionTypes?.CREATE &&
                    !isEmpty(clubName) &&
                    Array.isArray(clubName) ? (
                      clubName
                        ?.sort((a: any, b: any) => {
                          return a?.name?.localeCompare(b?.name);
                        })
                        ?.map((club: any) => (
                          <MenuItem key={club?.id} value={club?.id}>
                            <Checkbox checked={clubNames?.includes(club?.id)} />
                            {club?.name || t("noName")}
                          </MenuItem>
                        ))
                    ) : !isEmpty(clubName) && Array.isArray(clubName) ? (
                      clubName
                        ?.sort((a: any, b: any) => {
                          return a?.name?.localeCompare(b?.name);
                        })
                        ?.map((club: any) => (
                          <MenuItem key={club?.id} value={club?.id}>
                            <Checkbox checked={clubNames?.includes(club?.id)} />
                            {club?.name || t("noName")}
                          </MenuItem>
                        ))
                    ) : (
                      <>
                        <MenuItem value="No Data">{t("noData")}</MenuItem>
                      </>
                    )}
                  </Select>
                  {/* Show clubNamesError if it is not empty */}
                  {clubNamesError && (
                    <Typography variant="body2" color="error">
                      {clubNamesError}
                    </Typography>
                  )}
                </FormControl>
              )}

              <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                style={{ position: "absolute", bottom: 20, right: "15px" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={brownButtonStyle}
                  disabled={formik.isSubmitting}
                >
                   {action === ActionTypes?.CREATE
                    ? userType === adminUsers ? t("saveNewAdmin") : t("SAVENEWUSER")
                    : action === ActionTypes?.EDIT
                    ? userType === adminUsers ? t("updateAdmin"):t("updateUser")
                    : t("saveNewAdmin")}
                </Button>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Slide>
    </Modal>

    {/* Confirmation dialog for unsaved changes */}
    <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmClose}
      />
    </>
  );
};

export default AddNewUserModalForm;
