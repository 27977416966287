// apiService.ts
import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import {
  GET,
  PUT,
  POST,
  PATCH,
  DELETE,
  COACHING_MANAGEMENT,
  COACHING_SESSIONS_MANAGEMENT,
} from "../../config/API.constants";
import { USER_ROLE,BookingFilterStatus } from "../../config/constants";
import { transformFilters } from "app/shared/util/buildQuery";

import { Dispatch, AnyAction } from "redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "../../shared/util/toastHelper";
import {
  sessionRequested,
  sessionReceived,
  singleSessionReceived,
  updateSessionData,
  sessionsReset,
} from "../../redux/sessionReducer";
import { isEmpty } from "lodash";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const coachAvailabilityCheck =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COACHING_SESSIONS_MANAGEMENT.COACH_AVAILABILITY_CHECK}?limit=1000&page=1`,
        POST,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const getSingleSession =
  (id?: string, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COACHING_MANAGEMENT.GET_COACHING_DETAILS}/${id}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;
      dispatch(singleSessionReceived(apiData));

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createCoachingSession =
  (
    values: any,
    callback?: (data: any, error?: any) => void,
    messageText?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    let url = values?.isOverviewPage
    ? COACHING_SESSIONS_MANAGEMENT.OVERVIEW_COACHING_SESSION
    : values?.isCoachPortal
    ? values?.update
      ? `${COACHING_SESSIONS_MANAGEMENT.UPDATE_PLAYER_DATA}/${values?.bookingId}/user/${values?.user}/update`
      : COACHING_SESSIONS_MANAGEMENT.COACH_PORTAL_ADD_PLAYER
    : values?.update
    ? `${COACHING_SESSIONS_MANAGEMENT.UPDATE_PLAYER_DATA}/${values?.bookingId}/user/${values?.user}/update`
    : COACHING_SESSIONS_MANAGEMENT.ADD_COACHING_SESSION;

    try {
      response = await HTTP_CALL(
        url,
        POST,
        "",
        values
      );

      showToast(messageText || "Session created successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (callback && typeof callback === "function") {
        callback(apiData);
      }

      if (values?.isUpdateLocalData && !values?.isOverviewPage) {
        dispatch(updateSessionData(apiData));
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
        // else {
        //   showToast("No Data", "Error", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      if (typeof callback === "function") {
        callback("", error);
      }
      HANDLE_ERROR(error);
    }
  };


/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateCoachingSession =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `${
          values?.isCoachPortal
            ? COACHING_SESSIONS_MANAGEMENT.COACH_PORTAL_UPDATE_COACHING_SESSION
            : COACHING_SESSIONS_MANAGEMENT.ADD_COACHING_SESSION
        }/${id}${values?.isCoachPortal ? "/reschedule" : ""}`,
        PUT,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast("Coach session updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!isEmpty(apiData)) {
          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };



  export const createSessionAdditionMember =
  (
    values: any,
    callback?: (data: any, error?: any) => void,
    messageText?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    let url: string;

    if (values?.isOverviewPage) {
      url = COACHING_SESSIONS_MANAGEMENT.OVERVIEW_COACHING_SESSION;
    } else if (values?.isCoachPortal) {
      url = values?.update
        ? `${COACHING_SESSIONS_MANAGEMENT.UPDATE_PLAYER_DATA}/${values?.bookingId}/user/${values?.user}/update`
        : `${COACHING_SESSIONS_MANAGEMENT.BOOKING}/${values?.bookingId}/user/${values?.attendeeId}/add-member`;
    } else {
      url = values?.update
        ? `${COACHING_SESSIONS_MANAGEMENT.UPDATE_PLAYER_DATA}/${values?.bookingId}/user/${values?.user}/update`
        : `${COACHING_SESSIONS_MANAGEMENT.BOOKING}/${values?.bookingId}/user/${values?.attendeeId}/add-member`;
    }

    try {
      response = await HTTP_CALL(
        url,
        POST,
        "",
        values
      );

      showToast(messageText || "Player added successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (callback && typeof callback === "function") {
        callback(apiData);
      }

      if (values?.isUpdateLocalData && !values?.isOverviewPage) {
        dispatch(updateSessionData(apiData));
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      if (typeof callback === "function") {
        callback("", error);
      }
      HANDLE_ERROR(error);
    }
  };



/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateCoachingSessionAdditionMembers =
  (id: string, values: any, callback?: (data: any) => void, isDelete=false) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `/booking/${values?._id}/coaching-session/update-members`,
        PATCH,
        "",
        values?.attendees?.[0]
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast(isDelete ? "Coach session players deleted successfully":"Coach session players updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!isEmpty(apiData)) {

          if (typeof callback === "function") {
            callback(apiData);
          }

          if (values?.isUpdateLocalData && !values?.isOverviewPage) {
            dispatch(updateSessionData(apiData));
          }
          //return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else { 
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };



/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const changeSessionStatus =
  (
    id: string,
    userId?: any,
    isCoachPortal = false,
    mode = 'cancel',
    callback?: (data: any) => void
  ) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    let url: any = "";

    if(mode === 'cancelSession'){
      url = `${COACHING_SESSIONS_MANAGEMENT.COACH_PORTAL_CANCEL_COACHING_SESSION}/${id}/session`;
    } else if (isCoachPortal) {
      url = userId
        ? `${COACHING_SESSIONS_MANAGEMENT.COACH_PORTAL_REMOVE_SINGLE_PLAYER}/${id}/user/${userId}/remove-participant`
        : `${COACHING_SESSIONS_MANAGEMENT.COACH_PORTAL_CANCEL_COACHING_SESSION}/${id}/cancel`;
    } else {
      url = userId
        ? `${COACHING_SESSIONS_MANAGEMENT.ADD_COACHING_SESSION}/${id}/user/${userId}/remove`
        : `${COACHING_SESSIONS_MANAGEMENT.ADD_COACHING_SESSION}/${id}`;
    }

    try {
      response = await HTTP_CALL(url, DELETE, "");

      showToast(
        userId
          ? `Session player removed successfully!`
          : `Session cancelled successfully!`,
        "Success",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      const updatedCoachSessionData: any = await HTTP_CALL(
        `${COACHING_SESSIONS_MANAGEMENT.ADD_COACHING_SESSION_DETAIL}/${id}${
          isCoachPortal ? "/coaching-session" : ""
        }`
      );
      
      if (updatedCoachSessionData?.data?.data)
        dispatch(updateSessionData(updatedCoachSessionData?.data?.data));

      if (typeof callback === "function") {
        callback(updatedCoachSessionData?.data?.data);
      }

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const fetchSessionsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${
          params?.userType === USER_ROLE.COACH
            ? COACHING_SESSIONS_MANAGEMENT.GET_ALL_COACH_PORTAL_SESSIONS
            : COACHING_SESSIONS_MANAGEMENT.GET_ALL_SESSIONS
        }`,
        POST,
        "",
        { ...params, coaches: params?.coaches || [] }
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      dispatch(sessionReceived(apiData));

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };


  export const fetchCoachingSessionBookingList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      let qParams = {
        ...params,
        limit: params?.pageSize,
        sort: params?.sort || "createdAt|DESC",
        //filter_bookingType: "booking",
      };

      // if(params?.status === BookingFilterStatus.SCHEDULED || params?.status === BookingFilterStatus.INPROGRESS) {
      //   qParams.currentStatus = qParams?.status;
      //   delete qParams.status;
      // }

      // // Construct the filters object from the query parameter
      const filtersString: any = await transformFilters(qParams);
      const filteredSearchParams = new URLSearchParams(filtersString);

      if (!params?.isFilter) dispatch(sessionRequested());

      response = await HTTP_CALL(
        `${
          COACHING_MANAGEMENT.GET_COACHING_SESSION_BOOKINGS
        }?${filteredSearchParams.toString()}`,
        GET,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(sessionReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(sessionsReset());
};
