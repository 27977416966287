import React, { useCallback, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { debounce } from "lodash";

import { FormTextField } from "app/shared/atoms";

interface CourtsProps {
  courtIndex: number;
  formProps: FormikProps<any>;
}

// Debounce function to avoid excessive updates
const useDebouncedFieldUpdate = (
  setFieldValue: FormikProps<any>["setFieldValue"],
  courtIndex: number
) => {
  return useMemo(
    () =>
      debounce((field: string, value: string) => {
        setFieldValue(`courts[${courtIndex}].${field}`, value);
      }, 50),
    [courtIndex, setFieldValue]
  );
};

// Handle focus event to remove leading zeros
const handleFocus = (
  event: React.FocusEvent<HTMLInputElement>,
  field: string,
  setFieldValue: FormikProps<any>["setFieldValue"],
  courtIndex: number
) => {
  const value:any = event.target.value;
  const valueWithoutLeadingZeros =
    value && value !== "0" ? value.replace(/^0+(?=\d)/, "") : value;
  if(valueWithoutLeadingZeros)
    setFieldValue(`courts[${courtIndex}].${field}`, parseFloat(valueWithoutLeadingZeros));
};

// Handle blur event to format number
const handleBlur = (
  field: string,
  value: any,
  setFieldValue: FormikProps<any>["setFieldValue"],
  courtIndex: number
) => {
  const formattedValue = value && !isNaN(value) ? parseFloat(value) : "0";
  setFieldValue(`courts[${courtIndex}].${field}`, formattedValue);
};

const CourtPrices: React.FC<CourtsProps> = ({ courtIndex, formProps }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = formProps;

  const debouncedHandleCourtPriceChange = useDebouncedFieldUpdate(
    setFieldValue,
    courtIndex
  );

  const handleCourtPriceChange = useCallback(
    (field: string, val: string) => {
      const isValidNumber = /^\d*\.?\d{0,2}$/.test(val);  // Allows only digits and a single dot
      if (isValidNumber) {
        debouncedHandleCourtPriceChange(field, val);
      }
    },
    [debouncedHandleCourtPriceChange]
  );

  return (
    <>
      <Typography
        className="uppercase label"
        sx={{ margin: "1rem 0 1rem", color: "#818181" }}
      >
        {t("courtPrices")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography className="label" sx={{ color: "#4f4f4f" }}>
            {t("mins")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className="label" sx={{ color: "#4f4f4f" }}>
            {t("OffPeakHourPrice")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className="label" sx={{ color: "#4f4f4f" }}>
            {t("PeakHourPrice")}
          </Typography>
        </Grid>
      </Grid>

      {/* 60 minutes price - start */}
      <Grid
        container
        spacing={2}
        alignItems="start"
        sx={{ marginTop: "0.5rem" }}
      >
        <Grid item xs={4}>
          <Typography className="label" sx={{ marginTop: "15px" }}>
            60 {t("mins")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name={`courts[${courtIndex}].offPeakHourPrice`}
            autoComplete="off"
            value={values.courts[courtIndex]?.offPeakHourPrice || ""}
            customCallback={(val: string) =>
              handleCourtPriceChange("offPeakHourPrice", val)
            }
            placeholder={t("enterPrice")}
            showPriceSymbol
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleFocus(e, "offPeakHourPrice", setFieldValue, courtIndex)
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlur(
                "offPeakHourPrice",
                e.target.value,
                setFieldValue,
                courtIndex
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name={`courts[${courtIndex}].onPeakHourPrice`}
            autoComplete="off"
            value={values.courts[courtIndex]?.onPeakHourPrice || ""}
            customCallback={(val: string) =>
              handleCourtPriceChange("onPeakHourPrice", val)
            }
            placeholder={t("enterPrice")}
            type="number"
            showPriceSymbol
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleFocus(e, "onPeakHourPrice", setFieldValue, courtIndex)
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlur(
                "onPeakHourPrice",
                e.target.value,
                setFieldValue,
                courtIndex
              )
            }
          />
        </Grid>
      </Grid>
      {/* 60 minutes price - end */}

      {/* 90 minutes price - start */}
      <Grid
        container
        spacing={2}
        alignItems="start"
        sx={{ marginTop: "0.5rem" }}
      >
        <Grid item xs={4}>
          <Typography className="label" sx={{ marginTop: "15px" }}>
            90 {t("mins")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            fullWidth
            name={`courts[${courtIndex}].offPeakOneAndHalfHourPrice`}
            value={values.courts[courtIndex]?.offPeakOneAndHalfHourPrice || ""}
            customCallback={(val: string) =>
              handleCourtPriceChange("offPeakOneAndHalfHourPrice", val)
            }
            placeholder={t("enterPrice")}
            type="number"
            showPriceSymbol
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleFocus(
                e,
                "offPeakOneAndHalfHourPrice",
                setFieldValue,
                courtIndex
              )
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlur(
                "offPeakOneAndHalfHourPrice",
                e.target.value,
                setFieldValue,
                courtIndex
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name={`courts[${courtIndex}].onPeakOneAndHalfHourPrice`}
            autoComplete="off"
            value={values.courts[courtIndex]?.onPeakOneAndHalfHourPrice || ""}
            customCallback={(val: string) =>
              handleCourtPriceChange("onPeakOneAndHalfHourPrice", val)
            }
            placeholder={t("enterPrice")}
            type="number"
            showPriceSymbol
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleFocus(
                e,
                "onPeakOneAndHalfHourPrice",
                setFieldValue,
                courtIndex
              )
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlur(
                "onPeakOneAndHalfHourPrice",
                e.target.value,
                setFieldValue,
                courtIndex
              )
            }
          />
        </Grid>
      </Grid>
      {/* 90 minutes price - end */}

      {/* 120 minutes price - start */}
      <Grid
        container
        spacing={2}
        alignItems="start"
        sx={{ marginTop: "0.5rem" }}
      >
        <Grid item xs={4}>
          <Typography className="label" sx={{ marginTop: "15px" }}>
            120 {t("mins")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name={`courts[${courtIndex}].offPeakTwoHourPrice`}
            autoComplete="off"
            value={values.courts[courtIndex]?.offPeakTwoHourPrice || ""}
            customCallback={(val: string) =>
              handleCourtPriceChange("offPeakTwoHourPrice", val)
            }
            placeholder={t("enterPrice")}
            type="number"
            showPriceSymbol
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleFocus(e, "offPeakTwoHourPrice", setFieldValue, courtIndex)
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlur(
                "offPeakTwoHourPrice",
                e.target.value,
                setFieldValue,
                courtIndex
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            name={`courts[${courtIndex}].onPeakTwoHourPrice`}
            autoComplete="off"
            value={values.courts[courtIndex]?.onPeakTwoHourPrice || ""}
            customCallback={(val: string) =>
              handleCourtPriceChange("onPeakTwoHourPrice", val)
            }
            placeholder={t("enterPrice")}
            type="number"
            showPriceSymbol
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleFocus(e, "onPeakTwoHourPrice", setFieldValue, courtIndex)
            }
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlur(
                "onPeakTwoHourPrice",
                e.target.value,
                setFieldValue,
                courtIndex
              )
            }
          />
        </Grid>
      </Grid>
      {/* 120 minutes price - end */}
    </>
  );
};

export default CourtPrices;
