import { Grid } from "@mui/material";
import {
  users,
  endUsers,
  booking,
  coachingSessionBooking,
  credits,
  club,
  coachingManagement,
  tournaments,
  membershipProducts,
  userMemberships,
  tags,
  apparelList,
  userPurchaseList,
  coachingProducts,
  coupons,
  transactions
} from "../../config/constants";
import ClubFilterDialog from "app/entities/Clubs/modals/ClubFilterDialog";
import BookingFilterDialog from "app/entities/Bookings/modals/BookingFilterDialog";
import CoachingSessionBookingFilterDialog from "app/entities/Bookings/modals/CoachingSessionBookingFilterDialog";
import CreditFilterDialog from "app/entities/Organisations/modals/CreditFilterDialog";
import UserFilterDialog from "app/entities/Users/modals/UserFilterDialog";
import ExtraFilterDialog from "app/entities/Extras/modals/ExtraFilterDialog";
import { SearchBar } from "app/shared/components/SearchBar";
import MembershipFilterDialog from "app/entities/Memberships/modals/MembershipFilterDialog";
import TagFilterDialog from "app/entities/Tags/modals/TagFilterDialog";
import CoachingFilterDialog from "../../entities/Coaching/modals/CoachingFilterDialog";
import CouponFilterDialog from "../../entities/Coupons/modals/CouponFilterDialog";
import TournamentsFilterDialog from "../../entities/Tournaments/modals/TournamentsFilterDialog";
import TransactionFilterDialog from "app/entities/Transactions/modals/TransactionFilterDialog";
import UserPurchaseFilterDialog from "app/entities/UserPurchase/modals/UserPurchaseFilterDialog";

interface IFilter {
  pageName?: any;
  menuName?: any;
  features?: any;
  updateGridData?: any;
  setUpdateGridData?: any;
  loading?: any;
  refreshGrid?: any;
  callback?: any;
  clubsList?: any;
  categories?: any;
  memberships?: any;
  sessionPackages?:any;
  t?: any;
}
const Filter: React.FC<IFilter> = (filterProps) => {
  const { callback, menuName, t }: any = filterProps;

  const renderPageFilters = (filterProps: any) => {
    const { menuName, callback, clubsList, categories, memberships, sessionPackages }: any =
      filterProps;

    switch (menuName) {
      case coachingManagement:
        return <CoachingFilterDialog callback={callback} />;
      case club:
        return <ClubFilterDialog callback={callback} />;
      case users:
        return (
          <UserFilterDialog callback={callback} memberships={memberships} />
        );
      case endUsers:
        return (
          <UserFilterDialog
            callback={callback}
            memberships={memberships}
            tab={"users"}
          />
        );
      case booking:
        return <BookingFilterDialog callback={callback} />;
      case coachingSessionBooking:
        return <CoachingSessionBookingFilterDialog callback={callback} />;
      case credits:
        return <CreditFilterDialog callback={callback} sessionPackages={sessionPackages}/>;
      case tournaments:
        return <TournamentsFilterDialog callback={callback} />;
      case apparelList:
        return <ExtraFilterDialog callback={callback} clubsList={clubsList} />;
      case userPurchaseList:
        return (
          <UserPurchaseFilterDialog
            callback={callback}
            clubsList={clubsList}
            isPurchasePage={true}
          />
        );
      case membershipProducts:
        return (
          <MembershipFilterDialog callback={callback} categories={categories} />
        );
      case userMemberships:
        return (
          <MembershipFilterDialog
            callback={callback}
            categories={categories}
            isUserMembership={true}
          />
        );
      case tags:
        return <TagFilterDialog callback={callback} />;
      case coupons:
        return <CouponFilterDialog callback={callback} />;
      case transactions:
        return <TransactionFilterDialog callback={callback} />;
      default:
        return null;
    }
  };

  const placeholderText = (menuName?: string) => {
    switch (menuName) {
      case coachingManagement:
        return `${t("SearchBy")} ${t("coachName")}`;
      case club:
        return `${t("SearchBy")} ${t("club")}`;
      case users:
        return `${t("SearchBy")} ${t("userName")}`;
      case endUsers:
        return `${t("SearchBy")} ${t("userName")}`;
      case booking:
        return `${t("SearchBy")} ${t("clubNameOrUserName")}`;
      case coachingSessionBooking:
        return `${t("SearchBy")} ${t("clubNameOrUserName")}`;
      case tournaments:
        return `${t("SearchBy")} ${t("tournamentName")}`;
      case apparelList:
        return `${t("SearchBy")} ${t("products")}`;
      case userPurchaseList:
        return `${t("SearchBy")} ${t("userName")}`;
      case membershipProducts:
        return t("searchByKeywords");
      case userMemberships:
        return t("searchByKeywords");
      case tags:
        return `${t("SearchBy")} ${t("tags")}`;
      case coachingProducts:
        return `${t("SearchBy")} ${t("coachingProducts")}`;
      default:
        return t("search");
    }
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems={menuName === 'Transactions' ? "flex-end":"center"}
      mt={menuName === 'Transactions' ? "":"0.3rem"}
      //style={{ marginTop: "50px" }}
    >
      <Grid item xs={12} sm={4} style={{ paddingLeft: "0px" }}>
        <SearchBar
          onSearch={callback}
          placeholder={placeholderText(menuName)}
          menuName={menuName}
        />
      </Grid>
      <Grid item xs={12} sm={2}></Grid>
      <Grid
        item
        xs={12}
        sm={6}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        {renderPageFilters(filterProps)}
      </Grid>
    </Grid>
  );
};

export default Filter;
