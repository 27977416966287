import React, { useState, useCallback } from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	IconButton,
	Avatar,
	Typography,
	Divider,
	TextField,
	Button,
	Box,
	Menu,
	MenuItem,
	AvatarGroup,
	Chip,
	Backdrop,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { isEmpty, isEqual } from 'lodash';

import {
	FeedItemCardProps,
	IClubSummary,
	IMedia,
	ITopCommentSummary,
	ITopLikeSummary,
} from '../models/FeedPageModels';
import DeletePopup from '../../../shared/components/DeletePopup';
import { formatTimestamp } from '../../../shared/components/FormatDate';
import ModalFeedItemShare from './modals/ModalFeedItemShare';
import { useLocationQuery } from 'app/shared/util/useLocationQuery';
import { CDN_URL, FEED_ITEMS_LIST, FeedAttachmentSliderSettings } from '../../../config/constants';
import { deleteFeedItem, likeFeedItem } from '../apiService/feedAPIService';
import ModalFeedItemDetails from './modals/ModalFeedItemDetails';
import { useQueryClient } from '@tanstack/react-query';
import { replaceUserPatternWithName } from '../../../shared/util/common';

const FeedItemCard: React.FC<FeedItemCardProps> = ({
	post,
	onEdit,
	onFeedChange,
	setFeedLoading,
}) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [shareModalOpen, setShareModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);

	const searchParams = useLocationQuery();
	const queryClient = useQueryClient();

	const handleImageClick = (src: string) => {
		setSelectedImage(src);
	};

	const handleCloseImageOverlay = () => {
		setSelectedImage(null);
	};

	const handleCommentsClick = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleShareClick = () => {
		setShareModalOpen(true);
		handleClose();
	};

	const handleEditClick = () => {
		handleClose();
		onEdit(); // Call the onEdit function passed from ViewFeeds
	};

	const handleDeleteClick = async () => {
		setDeleteModalOpen(true);

		handleClose();
	};

	const actionDeleteFeedItem = async (feedId: string) => {
		await deleteFeedItem(feedId);
		queryClient.invalidateQueries({ queryKey: [FEED_ITEMS_LIST] });
	};

	const handleDeleteModalClose = () => {
		setDeleteModalOpen(false);
	};

	const refreshFeeds = async () => {
		handleDeleteModalClose();
		onFeedChange();
	};

	const handleClose = () => {
		setAnchorEl(null);
		onFeedChange();
	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const handleShareModalClose = () => {
		setShareModalOpen(false);
	};

	const renderClubChips = () => {
		if (!post?.clubs || post?.clubs.length === 0) {
			return null;
		}

		return (
			<div style={{ marginTop: '8px' }}>
				{post?.clubs?.map((clubData: IClubSummary) => {
					return (
						<Chip key={clubData._id} label={clubData.name} style={{ margin: '4px' }} />
					);
				})}
			</div>
		);
	};

	const renderLocalityChips = () => {
		if (!post?.locality || post?.locality.length === 0) {
			return null;
		}

		return (
			<div>
				{post?.locality?.map((x: any) => {
					return <Chip key={x} label={x} style={{ margin: '4px' }} />;
				})}
			</div>
		);
	};

	const renderAttachment = (attachment: IMedia) => {
		if (!attachment) return null;
		const src = `${CDN_URL}/${attachment.filePath}`;
		if (attachment.fileType === 'image') {
			return (
				<CardMedia
					key={attachment.mediaId}
					component="img"
					height="575px"
					alt={`Post Image ${attachment.mediaId}`}
					src={src}
					sx={{
						objectFit: 'scale-down',
						display: 'flex',
						alignItems: 'center',
						//backgroundColor: "rgba(0, 0, 0,0.8)", // Adjust the opacity as needed
						cursor: 'pointer',
					}}
					onClick={() => handleImageClick(src)}
				/>
			);
		} else if (attachment.fileType === 'video') {
			return (
				<CardMedia
					key={attachment.mediaId}
					component="video"
					height="auto"
					controls
					src={src}
					style={{
						maxWidth: '100%', // Ensure the image doesn't exceed the container width
					}}
				/>
			);
		} else {
			return null;
		}
	};

	const renderImageOverlay = () => {
		if (!selectedImage) return null;
		return (
			<Backdrop
				open={true}
				onClick={handleCloseImageOverlay}
				style={{
					zIndex: 1000,
				}}
			>
				<img
					src={selectedImage}
					alt="Full Size media"
					style={{
						maxWidth: '90%',
						maxHeight: '90%',
						objectFit: 'contain',
					}}
				/>
			</Backdrop>
		);
	};
	const renderLikedBy = (likes: ITopLikeSummary[], totalLikes: number = 0) => {
		if (likes?.length === 0) {
			return null;
		}

		const firstLike = likes[0];
		const likedBy = `${firstLike?.likedByDetails && firstLike?.likedByDetails[0]?.fullName}`;
		const remainingLikes = totalLikes - 1;

		return (
			<Box mt={2} display="flex" alignItems="center">
				<Typography>
					Liked by <strong>{likedBy}</strong>
					{remainingLikes > 0 && (
						<>
							{' '}
							<strong>
								and {remainingLikes} {remainingLikes === 1 ? 'other' : 'others'}
							</strong>
						</>
					)}
				</Typography>
				<Divider orientation="vertical" flexItem />
				<AvatarGroup>
					{likes?.slice(0, likes.length)?.map((like: ITopLikeSummary) => (
						<Avatar
							key={like?._id}
							src={like?.likedByDetails && like?.likedByDetails[0]?.profilePic}
							alt={`${like?.likedByDetails && like?.likedByDetails[0]?.fullName}`}
						/>
					))}
				</AvatarGroup>
			</Box>
		);
	};

	const renderCommentContent = (commentMessage: string, commentMentions: []) => {
		if (!commentMessage) return null;
		const contentWithMention = replaceUserPatternWithName(
			commentMessage,
			commentMentions || []
		);

		return (
			<Typography
				component={'span'}
				sx={{
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
				}}
			>
				{contentWithMention.split('\n').map((line, index) => (
					<React.Fragment key={line[index]}>
						<span dangerouslySetInnerHTML={{ __html: line }} />
					</React.Fragment>
				))}
			</Typography>
		);
	};

	const renderLatestComment = (comments: ITopCommentSummary[], totalComments: any) => {
		if (comments.length === 0) {
			return null;
		}

		// Find the latest comment based on the updatedAt field
		const latestComment: any = comments[0];

		// Display only the first three words with ellipsis
		const shortComment = latestComment?.message?.split(' ').slice(0, 13).join(' ');
		const showMore = latestComment?.message?.split(' ').length > 3;
		const remainingComments = totalComments;

		return (
			<Box mt={2}>
				<Typography>
					<strong>
						{' '}
						<Link
							to={`/users/${latestComment?.commenterDetails?.userId}?back=${
								window.location.pathname
							}&backObj=${JSON.stringify(searchParams)}&backText=back&isAdmin=${latestComment?.commenterDetails?.userType !== 'customer'}`}
              className='hylink'
						>
							{latestComment?.commenterDetails?.fullName}
						</Link>
					</strong>
					: {/* {shortComment} */}
					{renderCommentContent(shortComment, latestComment?.mentionsDetails || [])}
					{showMore && (
						<span
							style={{ color: '#727272', cursor: 'pointer' }}
							onClick={handleCommentsClick}
						>
							... (More)
						</span>
					)}
				</Typography>
				<Typography variant="caption" color="textSecondary">
					{formatTimestamp(latestComment?.createdAt)}
				</Typography>
				<Typography
					style={{ color: 'grey', cursor: 'pointer' }}
					onClick={handleCommentsClick}
				>
					View {remainingComments === 1 ? '' : 'all'} {remainingComments}{' '}
					{remainingComments === 1 ? 'comment' : 'comments'}
				</Typography>
			</Box>
		);
	};

	const handleLikeClick = useCallback(async () => {
		await likeFeedItem(post?._id);
		onFeedChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [post?._id]);

	const renderPostContent = () => {
		if (!post?.message) return null;
		const contentWithMention = replaceUserPatternWithName(
			post?.message,
			post?.mentionsDetails || []
		);
		return (
			<Typography>
				{contentWithMention.split('\n').map((line, index) => (
					<React.Fragment key={line[index]}>
						<span dangerouslySetInnerHTML={{ __html: line }} />
						<br />
					</React.Fragment>
				))}
			</Typography>
		);
	};

	return (
		<>
			<Card
				elevation={3}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					borderRadius: '10px',
				}}
			>
				<CardHeader
					avatar={
						<Link
							to={`/users/${post?.createdByDetails?.userId}?back=${
								window.location.pathname
							}&backObj=${JSON.stringify(searchParams)}&backText=back&isAdmin=true`}
							className='hylink'
						>
							<Avatar src={post?.createdByDetails?.profilePic} />
						</Link>
					}
					title={
						<Link
							to={`/users/${post?.createdByDetails?.userId}?back=${
								window.location.pathname
							}&backObj=${JSON.stringify(searchParams)}&backText=back&isAdmin=true`}
							className='hylink'
						>
							{post?.createdByDetails?.firstName}
						</Link>
					}
					action={
						<>
							<IconButton onClick={handleClick}>
								<MoreHorizIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={handleShareClick}>{t('share')}</MenuItem>
								<MenuItem onClick={handleEditClick}>{t('edit')}</MenuItem>
								<MenuItem onClick={handleDeleteClick} style={{ color: '#EB5757' }}>
									{t('delete')}
								</MenuItem>
							</Menu>
						</>
					}
				/>

				{post?.attachments && post?.attachments?.length >= 1 && (
					<Slider {...FeedAttachmentSliderSettings}>
						{post?.attachments?.map((attachment: IMedia) =>
							renderAttachment(attachment)
						)}
					</Slider>
				)}
				{post?.message && (
					<Box style={{ marginLeft: '18px', paddingTop: '3vh' }}>
						<Divider />
						{renderPostContent()}
					</Box>
				)}

				<CardContent
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
					}}
				>
					{post?.clubs && !isEmpty(post?.clubs) && (
						<Box display="flex" alignItems="center">
							{renderClubChips()}
						</Box>
					)}

					{post?.locality && !isEmpty(post?.locality) && (
						<Box display="flex" alignItems="center">
							{renderLocalityChips()}
						</Box>
					)}

					<Box display="flex" alignItems="center">
						{post?.gender && post?.gender !== '' && (
							<Chip
								key={post?.gender}
								label={`Gender: ${post?.gender}`}
								style={{ margin: '4px' }}
							/>
						)}
						{post?.ageRange && post?.ageRange?.length > 0 && (
							<Chip
								key={post?.ageRange.join('_')}
								label={`Age: ${post?.ageRange.join(' - ')}`}
								style={{ margin: '4px' }}
							/>
						)}
						{post?.isProEmails && (
							<Chip
								key={'proEmailsOnly'}
								label={`${t('proEmailsOnly')}`}
								style={{ margin: '4px' }}
							/>
						)}
					</Box>

					<Box mt={2} display="flex" alignItems="center">
						<FavoriteIcon
							style={{ color: '#EB5757', cursor: 'pointer' }}
							onClick={handleLikeClick}
						/>
						<Typography>{post?.totalLikes}</Typography>
						<ModeCommentIcon style={{ color: '#D9D9D9' }} />
						<Typography>{post?.totalComments}</Typography>
					</Box>
					{post?.topLikes && renderLikedBy(post?.topLikes, post?.totalLikes)}
					{post?.topComments &&
						renderLatestComment(post?.topComments, post?.totalComments)}
					<Box mt={2}></Box>
					<Divider />
					<Box display="flex" alignItems="center" style={{ marginTop: '15px' }}>
						<InsertEmoticonIcon />
						<TextField
							fullWidth
							placeholder={`${t('addAComment')}...`}
							variant="standard"
							InputProps={{
								disableUnderline: true, // Removes the underline
								style: { border: 'none', marginLeft: '5px', cursor: 'pointer' }, // Removes the border
							}}
							onClick={handleCommentsClick}
						/>
						<Button style={{ color: 'grey' }} onClick={handleCommentsClick}>
							{t('Post')}
						</Button>
					</Box>
				</CardContent>
			</Card>
			{shareModalOpen && (
				<ModalFeedItemShare
					open={shareModalOpen}
					onClose={handleShareModalClose}
					post={post}
				/>
			)}
			{deleteModalOpen && (
				<DeletePopup
					openModal={deleteModalOpen}
					id={post?._id}
					onClose={handleDeleteModalClose}
					entityName="feeds"
					modalTxt="feeds"
					refreshFeeds={refreshFeeds}
					action={() => {
						actionDeleteFeedItem(post?._id);
					}}
				/>
			)}

			{renderImageOverlay()}

			{modalOpen && (
				<ModalFeedItemDetails
					post={post}
					open={modalOpen}
					onClose={handleCloseModal}
					onEdit={onEdit}
					onFeedChange={onFeedChange}
					setFeedLoading={setFeedLoading}
				/>
			)}
		</>
	);
};

export default FeedItemCard;
